import { Alert, Modal, Text, TextH1, typography } from '@elements'
import { openUrl } from '@helpers/client'
import { formatAddress, formatPickupDate, formatPickupTime } from '@helpers/display'
import { isNonPickup } from '@models/Location'
import { Order, Pickup, isDraftPickup, isPickupItemActive } from '@models/Order'
import { isShare, isStandard } from '@models/Product'
import { useCallback, useMemo } from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { Image } from '../../components/Image'
import { Button } from '../../components/elements/Button'
import Colors from '../../constants/Colors'
import { CancelPickup } from './CancelPickup'
import { ConsumerCancel } from './ConsumerCancel'
import { PickupOrderItems } from './PickupOrderItems'
import { Reschedule } from './Reschedule'

import { isWeb } from '@/constants/Layout'
import { formatDistributionType } from '@helpers/location'
import { isPickupItemChangeOptionBlocked } from '@helpers/order'
import { ChangeOptionKeys } from '@models/CSA'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { OrdersParamList } from '../../navigation/types'

type PropTypes = {
  pickup: Pickup
  due?: string
  goToPickupInvoices: () => void
  onMessageFarmPress: () => void
}

/** Pickup Card used on orders Screen - contain all the details for a pickup */
export function PickupCard({ pickup, due, goToPickupInvoices, onMessageFarmPress }: PropTypes) {
  const farm = pickup.farm
  const styles = responsiveStyle()
  const handleReschedule = useCallback(() => rescheduleModal(pickup), [pickup])
  const navigation = useNavigation<StackNavigationProp<OrdersParamList, 'Orders'>>()

  const goToOrderDetails = useCallback(
    (orderId: Order['id']) => {
      if (isDraftPickup(pickup)) {
        navigation.navigate('DraftOrderDetails', { draftOrderId: orderId })
      } else {
        navigation.navigate('OrderSummary', { orderId })
      }
    },
    [navigation, pickup],
  )

  return (
    <View style={styles.card}>
      <View style={styles.info}>
        <View style={styles.farmInfo}>
          <Image type="logo" style={styles.farmLogo} source={{ uri: farm?.logo }} />
          <TextH1 numberOfLines={2} size={18}>
            {farm.name}
          </TextH1>
        </View>
        <View style={styles.pickupInfo}>
          <View style={styles.pickupInfoBlock}>
            <Text size={12} type="regular" style={styles.pickupSmallTitle}>
              Date &amp; Time
            </Text>
            <TextH1 size={18} type="bold">
              {formatPickupDate(pickup.date)}
            </TextH1>
            <Text size={16} type="regular" style={styles.pickupMediumText}>
              {formatPickupTime(pickup.distribution.hours, pickup.distribution.locationType)}
            </Text>
            {!!due && (
              <TouchableOpacity onPress={goToPickupInvoices}>
                <Text size={16} type="regular" style={styles.pickupDue}>
                  {due} due before {formatDistributionType({ type: pickup.distribution.locationType })}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.spacing} />
          <View style={styles.locationBlock}>
            <Text size={12} type="regular" style={styles.pickupSmallTitle}>
              Location
            </Text>
            <TextH1 size={18} type="bold">
              {pickup.distribution.locationName}
            </TextH1>
            <Text size={16} type="regular" style={styles.pickupMediumText}>
              {formatAddress(pickup.distribution.address)}
            </Text>
            <View style={styles.pickupActions}>
              <TouchableOpacity onPress={() => openMaps(pickup)}>
                <Text type="bold" style={styles.pickupAction}>
                  Get directions
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => openEmail(farm)}>
                <Text type="bold" style={styles.pickupAction}>
                  Email
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => openTel(farm)} disabled={!farm?.phoneNumber}>
                <Text type="bold" style={[styles.pickupAction, !farm.phoneNumber && styles.pickupActionDisabled]}>
                  Call
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <PickupOrderItems pickup={pickup} goToOrderDetails={goToOrderDetails} />
      </View>
      {!isDraftPickup(pickup) ? (
        <View>
          {/*<Button title="Message Farm" onPress={onMessageFarmPress} />*/}
          <Text size={10} color={Colors.shades[200]} type="medium">
            Please contact your farmer to request changes.
          </Text>
        </View>
      ) : (
        <View style={styles.pickupButtons}>
          <VacationOrCancelButton pickup={pickup} />
          {!rescheduleOptions('blockRescheduling', pickup) && (
            <Button
              outline
              title="Reschedule"
              // Not allow to reschedule for delivery and shipping for now
              disabled={isNonPickup(pickup.distribution.locationType)}
              onPress={handleReschedule}
            />
          )}
          <CancelOrderButton pickup={pickup} />
        </View>
      )}
    </View>
  )
}
function rescheduleOptions(optionName: ChangeOptionKeys, pickup: Pickup) {
  const items = pickup.items.filter((itm) => isPickupItemActive(itm))
  return items.every((pItm) => isPickupItemChangeOptionBlocked(optionName, pItm))
}

export const rescheduleModal = (pickup: Pickup) => {
  if (isDraftPickup(pickup))
    return Alert(
      'Cannot reschedule',
      'This pickup has not yet been confirmed by the farm, please contact your farmer to make changes.',
    )
  Modal(<Reschedule pickup={pickup} />, { title: 'Reschedule Item' })
}

function VacationOrCancelButton({ pickup }: { pickup: Pickup }) {
  const title = getTitleVacationOrCancel(pickup)

  return <Button outline title={title} onPress={() => cancelPickupOrClaimVacationModal(pickup, title)} />
}

export const getTitleVacationOrCancel = (pickup: Pickup): string => {
  const defaultCancelPickupTitle = `Claim Vacation / Cancel ${formatDistributionType(
    {
      type: pickup.distribution.locationType,
    },
    { capitalize: true },
  )}`
  let title: string
  const items = pickup.items.filter((itm) => isPickupItemActive(itm))
  if (!items) title = defaultCancelPickupTitle
  else {
    const hasShares = items.some((pItm) => isShare(pItm.product))
    const hasStd = items.some((pItm) => isStandard(pItm.product))
    if (!hasShares && !hasStd) title = defaultCancelPickupTitle
    else
      title = `${hasShares ? 'Claim Vacation' : ''}${hasShares && hasStd ? ' / ' : ''}${
        hasStd
          ? `Cancel ${formatDistributionType({ type: pickup.distribution.locationType }, { capitalize: true })}`
          : ''
      }`
  }
  return title
}

export const cancelPickupOrClaimVacationModal = (pickup: Pickup, title: string) => {
  const items = pickup.items.filter(isPickupItemActive)
  if (items.length === 0)
    return Alert('Contact Farm', 'In order to make changes to this pickup, please contact your farm admin.')
  if (isDraftPickup(pickup))
    return Alert(
      'Contact Farm',
      'This pickup has not yet been confirmed by the farm, please contact your farmer to make changes.',
    )

  Modal(<CancelPickup pickup={pickup} />, {
    title,
  })
}

function CancelOrderButton({ pickup }: { pickup: Pickup }) {
  const hasShares = useMemo(() => {
    return pickup.items.filter((itm) => isPickupItemActive(itm)).some((itm) => isShare(itm.product))
  }, [pickup])

  return hasShares ? <Button outline title="Cancel Order" onPress={() => cancelModal(pickup)} /> : null
}

const cancelModal = (pickup: Pickup) => Modal(<ConsumerCancel pickup={pickup} />, { title: 'Cancellation Request' })

const openMaps = async (pickup: Pickup) => {
  let result
  try {
    if (!pickup.distribution.address) throw new Error('No address')
    const address = formatAddress(pickup.distribution.address)
    const url = Platform.select({
      ios: 'http://maps.apple.com/maps?saddr=My+Location&daddr=',
      default: 'http://maps.google.com/maps?saddr=My+Location&daddr=',
    })
    result = await openUrl(url + address)
    if (!result) Alert(`Could not open the map. ${isWeb ? 'Check if your browser is blocking it.' : ''}`)
  } catch (error) {
    Alert('Something went wrong while opening the map.')
  }
}

const openEmail = async (farm: Pickup['farm']) => {
  let result
  try {
    if (!farm.email) throw new Error('No email')
    result = await openUrl(`mailto:${farm!.email}`)
    if (!result) Alert(`Could not open the email client. ${isWeb ? 'Check if your browser is blocking it.' : ''}`)
  } catch (e) {
    Alert('Something went wrong while opening the email app.')
  }
}

const openTel = async (farm: Pickup['farm']) => {
  let result
  try {
    result = await openUrl(`tel:${farm?.phoneNumber}`)
    if (!result) Alert(`Could not open the phone application. ${isWeb ? 'Check if your browser is blocking it.' : ''}`)
  } catch (e) {
    Alert('Something went wrong while opening the phone app.')
  }
}

const responsiveStyle = CreateResponsiveStyle(
  {
    card: {
      flexDirection: 'row',

      marginVertical: 16,
      marginHorizontal: 10,
      borderRadius: 10,
      borderWidth: 1,
      padding: 32,
      borderColor: Colors.shades['100'],
      backgroundColor: Colors.white,
    },
    info: {
      flex: 1,
    },
    farmInfo: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    farmLogo: {
      width: 58,
      height: 58,
      marginRight: 15,
      borderRadius: 30,
    },
    pickupInfo: {
      flexDirection: 'row',
      marginBottom: 20,
      flexWrap: 'wrap',
    },
    pickupInfoBlock: {
      marginTop: 30,
      flex: 1,
      minWidth: 200,
    },
    locationBlock: {
      marginTop: 30,
      flex: 2,
      minWidth: 300,
    },
    pickupSmallTitle: {
      fontFamily: typography.body.regular,
      marginBottom: 16,
    },
    pickupMediumText: {
      fontFamily: typography.body.regular,
      fontWeight: '400',
    },
    pickupDue: {
      fontFamily: typography.body.regular,
      color: Colors.red,
      marginTop: 16,
      textDecorationLine: 'underline',
    },
    spacing: {
      width: 20,
    },
    pickupActions: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 16,
      marginRight: 15,
    },
    pickupAction: {
      color: Colors.green,
    },
    pickupActionDisabled: {
      color: Colors.lightGray,
    },
    pickupButtons: {},
  },

  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      card: {
        padding: 12,
      },
    },
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      card: {
        flexDirection: 'column',
      },
      pickupButtons: {
        // Buttons will be below pickup data
        alignSelf: 'center',
        marginTop: 20,
      },
    },
  },
)
