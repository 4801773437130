import { ErrorText } from '@elements'
import { MoneyCalc } from '@helpers/money'
import { Optional } from '@helpers/typescript'
import { Zero } from '@models/Money'
import { DefaultCatalog, Standard, Unit, UnitProductCommon } from '@models/Product'
import { useFormikContext } from 'formik'
import { useRef } from 'react'
import { View } from 'react-native'

import FormSectionHeader from '../components/FormSectionHeader'

import { FormikArray } from '@/admin/components/elements/FormikArray'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'
import { nonEmptyString } from '@helpers/helpers'
import { RenderBuyingOption } from './AdvancedPricing-components'
import { getSinglePrice, toPriceCatalog } from './AdvancedPricing-helpers'
import { UnitsForm } from './UnitsComponent'
import { ProductTypeForm } from './helpers/ProductTypeInfo'

/** sku is required on the type but optional in the form because it is built with the prefix. */
export type FormUnit = Optional<Unit, 'sku'>

export type AdvancedPricingForm = {
  /** form's "buyingOptions" holds the UnitProduct['units'] array data */
  buyingOptions: FormUnit[]
  /** UI only value that modifies the form behavior for the pricing catalog selected */
  catalog: DefaultCatalog
} & Pick<Standard, 'unitStock'> &
  Pick<UnitProductCommon, 'unitSkuPrefix'>

/** Local type that joins the pricing form type with other form types, from other form components of the overall product form */
export type ExtendedPricingForm = AdvancedPricingForm & UnitsForm & ProductTypeForm

export type BuyingOptionBuilderOpts = {
  catalog?: DefaultCatalog
  unitStock: boolean
}

/** Product form section for the fields related to buying options */
export function AdvancedPricingComponent() {
  const styles = useStyles()
  const firstRenderIsDone = useRef(false)

  const { values: formValues, setFieldValue, errors, touched } = useFormikContext<ExtendedPricingForm>()

  /** When pricePerUnit value changes in a unitStock product, recalculate the buying options with the new pricePerUnit */
  useFocusFx(
    () => {
      if (!firstRenderIsDone.current) {
        // This useEffect will only have functionality after first render is done
        firstRenderIsDone.current = true
        return
      }
      if (!formValues.unitStock) {
        // Nothing to do if the product is global stock, because pricePerUnit is only for unitStock products
        return
      }

      // Assume in a unitStock product there will be a single price per BO, because catalog can only be either wholesale or retail but not both

      // Convert the BOs to have a single price based on the value of the price per unit, and whose catalog association is for the currently selected catalog value in the form
      const newBuyingOptions: FormUnit[] = formValues.buyingOptions.map((bo) => {
        if (formValues.catalog !== DefaultCatalog.WholesaleRetail) {
          // In this scenario (Either wholesale or retail but not both), prices for each BO of the unitStock product will be coerced to become a single-item array with the price amount recalculated from the pricePerUnit and multiplier, and the single price will be associated to the current catalog value in the form
          return {
            ...bo,
            prices: [
              {
                ...getSinglePrice(bo, formValues.catalog, formValues.unitStock),
                amount: MoneyCalc.multiply(formValues.pricePerUnit ?? Zero, Number(bo.multiplier) || 0),
              },
            ],
          }
        } else {
          // In the scenario of a wholesale-retail, we simply re-calculate the amount in all the bo prices, and allow multiple prices to exist
          // Recalculating the prices might be inconvenient, so this might get discussed later
          return {
            ...bo,
            prices: bo.prices.map((pr) => ({
              ...pr,
              amount: MoneyCalc.multiply(formValues.pricePerUnit ?? Zero, Number(bo.multiplier) || 0),
            })),
          }
        }
      })
      setFieldValue('buyingOptions', newBuyingOptions)
    },
    // This only need to listen formValues.pricePerUnit change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues.pricePerUnit],
    { noRefocus: true },
  )

  /** When the form catalog changes to be only retail or only wholesale, associate the buying options' prices to the new catalog value */
  useFocusFx(() => {
    if (!firstRenderIsDone.current) {
      // This useEffect will only have functionality after first render is done
      firstRenderIsDone.current = true
      return
    }

    if (formValues.catalog === DefaultCatalog.WholesaleRetail) {
      // This is only meant to run if the new catalog is either wholesale-only or retail-only. Because those are the options that require saving a single price per BO which must be associated with the product catalog. In the case this changed to wholesale-retail, the existing single price can be left alone
      return
    }

    const newBuyingOptions = formValues.buyingOptions.map((bo) => {
      const price = getSinglePrice(bo, formValues.catalog, formValues.unitStock)

      price.priceGroup = {
        type: 'default-catalog',
        catalog: toPriceCatalog(formValues.catalog),
      }

      return {
        ...bo,
        prices: [price],
      }
    })
    setFieldValue('buyingOptions', newBuyingOptions)
    // only should run on catalog change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.catalog])

  return (
    <View style={styles.formMainContainer}>
      <FormSectionHeader title="Buying Options & Prices" />
      {!!touched.buyingOptions && !!errors.buyingOptions && (
        <ErrorText>
          {/* Under some circumstances this cannot receive the error string value straight from the `errors.buyingOptions` because its shape may not be something that can be used by the ErrorText. The error strings may be nested within an array of objects where each array element represents a buying option, and each of the objects' properties represents a field of the BO, and so on in a nested manner. It basically immitates the shape of the model. So if it's not a string, then you CAN'T just pass the value from the errors object into the text element. The approach will be this ErrorText will give a generic message AND each buying option will give the more specific error at each level beneath */}
          {nonEmptyString(errors.buyingOptions) ? errors.buyingOptions : 'There is a problem with the buying options'}
        </ErrorText>
      )}
      <FormikArray<AdvancedPricingForm, 'buyingOptions'>
        name="buyingOptions"
        renderItem={({ formik, arrayHelpers, index }) => (
          <RenderBuyingOption
            key={`units[${index}]`}
            formik={formik}
            arrayHelpers={arrayHelpers}
            index={index}
            formValues={formValues}
            firstRenderIsDone={firstRenderIsDone.current}
          />
        )}
      />
    </View>
  )
}

export const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    buyingOptionContainer: {
      alignItems: 'center',
      marginVertical: 5,
      flex: 1,
      flexDirection: 'row',
    },
    colorCircle: {
      width: 20,
      height: 20,
      borderRadius: 10,
      marginVertical: 'auto',
    },
    pricesContainer: {
      paddingLeft: 10,
    },
    pricing: {
      alignItems: 'flex-start',
    },
    addBuyingOpt: { width: 250 },
    formMainContainer: {
      paddingHorizontal: isSmallDevice ? 0 : 25,
      paddingBottom: 40,
    },
    addPrice: {
      alignSelf: 'flex-end', // This forces the button to appear on the right side of the form, below the prices
    },
  }))
