import { CSA } from '@models/CSA'
import { Distribution } from '@models/Distribution'
import { isNonPickup, isNonPickupDistLocation } from '@models/Location'
import { CartItem, isCartStandard } from '@models/Order'
import { PaymentSchedule, PhysicalProduct, Unit, isAddon, isShare, isStandard } from '@models/Product'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { useCartService } from '../useCartService'

import { Logger } from '@/config/logger'
import { CartServiceType } from '@/constants/types/cartService'
import { canUpdateQuantity } from '@helpers/canUpdateQuantity'
import { findPriceForAppMode, matchesAppModeSchedule } from '@helpers/products'
import { isSameDay } from '@helpers/time'
import { isValidAddress } from '@models/Address'
import { DataError } from '@shared/Errors'
import { setLocation } from '../../../components/AddToCartFlow-components/SetLocation'
import { setScheduleAndDates } from '../../../components/AddToCartFlow-components/SetScheduleAndDates'
import { getAutoSelectedSchedule, getCanAddScheduleBasic, getPickupsCacheAddtoCartFlow } from './helpers'
import { OnSuccessFn } from './useAddToCartFlow'

/** Options for the setLocation callback which is called as part of the addToCart flow. This is meant for any options which are variable depending on the item being added to the cart. */
export type SetLocationFlowOpts = {
  prod: PhysicalProduct
  /** in case the schedule is selected in advance */
  distribution?: Distribution
  /** matchingDistros are the product distributions of same id as distros of items in the cart; These will be used to determine if a distro can be selected automatically. It is assumed these distros are non-hidden, non-closed (if consumer mode), and have pickups left for this product */
  matchingSchedulesCart: Distribution[]
  /** this will limit the distro options to these, for the purposes of auto-selection and manual selection. This is intended to be used for availAddons matchingDistIds based on cart or past purchases, so the available addons will always be available at these distros only. They may coincide with the matching distros from cart, or they may not, in the case of matching distros based on past purchases. Some may be NonPickup and may not have a delivery address */
  matchingSchedulesAvailAddon?: Distribution[]
  /** unit selected in previous steps. required for unit products. If it's a standard product, the unit argument will be originated from the unit selection modal. */
  unit?: Unit
  /** csa selected in previous steps. required for shares */
  csa?: CSA
  /** The pay schedule to be selected in advance */
  paymentSchedule?: PaymentSchedule
  /** will be invoked with result from addToCart, or void if a modal gets dismissed. It resolves the outer promise of the main addToCart flow. It is expected to hide the modal, so it is not necessary to call hideModal from here. */
  onSuccess: OnSuccessFn
  /** should be called on errors. It rejects the outer promise of the main addToCart flow. It is expected to hide the modal, so it is not necessary to call hideModal from here. */
  onErr: (err: unknown) => void
  /** When `autoAddMatchingDates` is true... If prod is standard and only one schedule matches, we allow for adding automatically if the product is available on any same date as another cartitem with that schedule */
  enabledAutoAddMatchingDates?: boolean
  /** `providedPickups` are an optional array of pickup dates that should be added to cart for this product. Only intended for standard products if pickups are selected in advance of calling this helper by some other means. If defined, this should make SetPickup modal skip the date selector by adding these, just like it should do in case of auto-add matching dates. */
  providedPickups?: DateTime[]
}

/** Options for the main hook that provides the setLocation callback. This is meant for any options which are constant. */
export type UseSetLocationFlowOpts = {
  cartServiceType?: CartServiceType
  /** modifies behaviors specific to the given app mode. In the admin side this can be undefined. */
  isWholesale: boolean | undefined
}

/** the last step of the add-to-cart flow which handles selecting location, address, schedule, and dates
 *
 * Tips:
 * - Don't use toasts or alerts here, because the onSuccess and onErr callbacks should already include a confirmation alert, which is configurable based on the arguments passed. Use that instead.
 */
export type SetLocationFlow = (opts: SetLocationFlowOpts) => Promise<void>

/** Step where a product may select a CSA */
export function useSetLocationFlow({ cartServiceType = 'consumer', isWholesale }: UseSetLocationFlowOpts) {
  const { cart, addToCart, isAdmin } = useCartService({ cartServiceType, isWholesale })

  return useCallback<SetLocationFlow>(
    async function setLocationFlow({
      prod,
      distribution,
      enabledAutoAddMatchingDates = true,
      matchingSchedulesCart,
      matchingSchedulesAvailAddon,
      csa,
      paymentSchedule,
      providedPickups,
      onSuccess,
      onErr,
      unit,
    }) {
      if (!isAdmin && isWholesale === undefined) {
        Logger.error(
          new Error("The addToCart flow is being called before the app mode is defined. This shouldn't happen"),
        )
        return onSuccess(undefined, { msg: 'Some resources are still initializing. Please try again', showMsg: true })
      }

      if (isAddon(prod) && !isAdmin && !matchingSchedulesAvailAddon?.length) {
        return onErr(new Error('Missing a list of available distros for the addon in consumer mode'))
      }

      // We will auto-select the distro if there is only one viable distro to select
      const autoSelectedSchedule = getAutoSelectedSchedule({
        prod,
        distribution,
        matchingSchedulesCart,
        matchingSchedulesAvailAddon,
        isAdmin,
        isWholesale,
      })

      // If this is true, it will enter the flow for auto-selected distro
      if (autoSelectedSchedule && getCanAddScheduleBasic({ dist: autoSelectedSchedule, isAdmin, isWholesale })) {
        // This distro will be automatically selected

        /** This is a sanity check and shouldn't happen because all the routes that would lead to a single schedule should have their own way to prevent it from fulfilling this condition. BUT Just in case... */
        if (autoSelectedSchedule.isHidden || (!isAdmin && autoSelectedSchedule.closed)) {
          const err = new DataError(
            "A physical product couldn't be added to cart because an auto-selected schedule is either hidden or closed. This shouldn't happen.",
            { prod, autoSelectedSchedule },
          )
          return onErr(err)
        }

        const pickups =
          providedPickups ??
          getPickupsCacheAddtoCartFlow(autoSelectedSchedule, prod, {
            excludeClosedDistros: !isAdmin,
            ignoreDisableBuyInFuture: isAdmin,
            ignoreOrderCutoffWindow: isAdmin,
          })

        /** This scenario should also be prevented if all is going well, because the matching schedules should be those with pickups before they reach this step */
        if (!pickups.length) {
          return onErr(
            new DataError(
              "A physical product couldn't be added to cart due to a lack of pickup dates left for this product at the auto-selected schedule. This should've been prevented by the UI.",
              { prod, autoSelectedSchedule },
            ),
          )
        }

        const canAddResult = canUpdateQuantity({
          cart,
          cartItem: { product: prod, quantity: 1, pickups, unit },
          isWholesale,
        })

        // If it's a share and the canAddResult is positive, we can already add automatically because there's no date selection step for shares
        if (isShare(prod) && canAddResult) {
          try {
            const res = await addToCart({
              product: prod,
              distribution: autoSelectedSchedule,
              pickups,
              csa,
              paymentSchedule,
              isAdmin,
            })
            return onSuccess(res, { autoAddedDistro: true, autoAddedDates: false })
          } catch (err) {
            return onErr(err)
          }
        } else {
          // Next comes the check for potential auto date-selection for Standard prods.

          //For date-selection, we allow for adding automatically if the product either:
          // 1) is available on any same date as a cartitem with matching schedule,
          // OR 2) has a single pickup left at this schedule.
          // AND it must have enough stock for the auto-added dates

          // Only do this if autoAddMatchingDates is enabled
          if (enabledAutoAddMatchingDates) {
            // Get non-share physical items in cart with the same schedule
            // We check only cartStandards, because cartShare pickups don't get selected in a custom way
            const itemsMatchingSchedule = cart.filter(isCartStandard).filter(
              // So for autoAdd purposes, we only want to consider pickups in cart which were selected manually by the user
              (ci) => ci.distribution?.id === autoSelectedSchedule.id,
            )

            //Gets the possible pickup dates which are the same as the selected pickup dates for itemsMatchingSchedule
            const matchingPickups = pickups.filter((d) =>
              itemsMatchingSchedule.find((ci) =>
                ci.pickups.find((d2) => isSameDay(d, d2, autoSelectedSchedule.location.timezone)),
              ),
            )

            // These are the tentative pickups to auto-add to the cart
            let pickupsToAutoAdd: CartItem['pickups'] = []
            // If matching pickups have any length, we can use them for auto-add
            // Else we can use the calculated pickups if they are a single one
            if (matchingPickups.length || pickups.length === 1) {
              pickupsToAutoAdd = matchingPickups.length ? matchingPickups : pickups
            }

            // Before auto-adding we must ensure this operation doesn't exceed stock
            const canAddResult = canUpdateQuantity({
              cart,
              cartItem: { product: prod, quantity: 1, pickups: pickupsToAutoAdd, unit },
              isWholesale,
            })

            // This is the last check before auto-adding both schedule and dates
            if (
              isStandard(prod) &&
              pickupsToAutoAdd.length >= (isWholesale ? 1 : prod.minPickups ?? 1) &&
              canAddResult
            ) {
              try {
                const res = await addToCart({
                  product: prod,
                  distribution: autoSelectedSchedule,
                  pickups: pickupsToAutoAdd,
                  unit, // Unit and price are expected to be defined here for this to work.
                  price: unit ? findPriceForAppMode(unit.prices, isWholesale) : undefined,
                  csa,
                  paymentSchedule,
                  isAdmin,
                })
                return onSuccess(res, {
                  autoAddedDistro: !!autoSelectedSchedule,
                  autoAddedDates: !!pickupsToAutoAdd.length,
                })
              } catch (err) {
                return onErr(err)
              }
            }
          }

          // Next: If there's an auto-added schedule, but it was not possible to auto-add dates, we must let the setScheduleAndDates modal handle the schedule and date selection. If it's Standard, it should allow them to select the date/s, and the auto-added schedule should be pre-selected.
          try {
            const scheduleAndDates = await setScheduleAndDates({
              prod,
              initialSchedule: autoSelectedSchedule,
              // The autoSelected schedule must be the only schedule choice presented to the user.
              scheduleFilter: [autoSelectedSchedule.id],
              locFilter: [autoSelectedSchedule.location],
              unit,
              cartServiceType,
              isWholesale,
            })
            if (!scheduleAndDates) {
              return onSuccess()
            }

            const { schedule, dates } = scheduleAndDates

            /** The modal component should be in charge of only allowing a valid stock to continue. But this is an additional layer of safety against purchases beyond stock available */
            const canAddResult = canUpdateQuantity({
              cart,
              cartItem: { product: prod, quantity: 1, pickups: dates, unit },
              isWholesale,
            })

            if (canAddResult) {
              /** If the stock check was successful for the autoSelectedSchedule and manually selected dates, we can add to cart.
               * Otherwise the flow should allow the user to select both the schedule and dates. */
              const res = await addToCart({
                product: prod,
                distribution: schedule,
                pickups: dates,
                unit, // Unit and price are expected to be defined here for this to work.
                price: unit ? findPriceForAppMode(unit.prices, isWholesale) : undefined,
                csa,
                paymentSchedule,
                isAdmin,
              })
              return onSuccess(res, { autoAddedDistro: !!autoSelectedSchedule, autoAddedDates: false })
            }
          } catch (err) {
            return onErr(err)
          }
        }
      }

      // Next: All manual selection. This is the case where nothing gets auto-added (schedule or dates)

      /**
       * These are the schedules that will be shown as options to choose from.
       * - If it's an addon in consumer mode, choose only from the matching availAddon distros.
       * - Else if there are any matching schedules, use them.
       * - Else, use the product schedules
       */
      const selectableSchedules = (
        isAddon(prod) && !isAdmin
          ? matchingSchedulesAvailAddon!
          : matchingSchedulesCart.length > 1
          ? matchingSchedulesCart
          : prod.distributions
      )
        .filter((d) => !d.isHidden && (isAdmin ? true : !d.closed))
        .filter(matchesAppModeSchedule(isWholesale))

      if (!selectableSchedules.length) {
        const err = new DataError(
          "A physical product couldn't be added to cart due to lack of valid distribution options. ",
          { prod, matchingSchedules: matchingSchedulesCart },
        )
        return onErr(err)
      }

      // Flow: Get the location, schedule and dates from user input through modals flow

      try {
        // Next step: location selection
        const selectableLocs = await setLocation({ prod, selectableSchedules, cartServiceType, isWholesale })

        if (!selectableLocs) {
          return onSuccess()
        }

        // These address validations are here for sanity, and shouldn't happen if the location selection modal is working correctly
        if (!selectableLocs.length) {
          return onErr(
            new DataError('No locations were returned in setLocation', { prod, selectableSchedules, isAdmin }),
          )
        }

        // Validate location types and addresses
        selectableLocs.forEach((loc) => {
          if (isNonPickup(loc)) {
            if (!loc.address) {
              return onErr(new DataError('No delivery address was assigned on location selection', { loc, prod }))
            }
            if (!isValidAddress(loc.address, { allowPO: false })) {
              return onErr(
                new DataError("Invalid address was allowed in location selection. This shouldn't happen.", {
                  loc,
                  prod,
                }),
              )
            }
          }
        })

        // Next step: Schedule and date selection
        const scheduleAndDates = await setScheduleAndDates({
          prod,
          scheduleFilter: selectableSchedules.map((sch) => sch.id),
          locFilter: selectableLocs,
          unit,
          cartServiceType,
          isWholesale,
        })

        if (!scheduleAndDates) {
          return onSuccess()
        }
        const { schedule, dates } = scheduleAndDates

        if (isNonPickupDistLocation(schedule.location) && isNonPickupDistLocation(selectableLocs[0])) {
          // Assign the address of the selected delivery location onto the selected schedule's location
          schedule.location.address = selectableLocs[0].address
        }

        /** The modal component should be in charge of only allowing a valid stock to continue. But this is an additional layer of safety against purchases beyond stock available */
        const canAddResult = canUpdateQuantity({
          cart,
          cartItem: { product: prod, quantity: 1, pickups: dates, unit },
          isWholesale,
        })
        if (!canAddResult) {
          return onSuccess(undefined, { msg: 'The stock is insufficient', alertType: 'alert', showMsg: true })
        }

        const res = await addToCart({
          product: prod,
          distribution: schedule,
          pickups: dates,
          unit,
          price: unit ? findPriceForAppMode(unit.prices, isWholesale) : undefined,
          csa,
          paymentSchedule,
          isAdmin,
        })
        return onSuccess(res, { autoAddedDistro: false, autoAddedDates: false })
      } catch (error) {
        onErr(error)
      }
    },
    [cart, addToCart, isAdmin, isWholesale, cartServiceType],
  )
}
