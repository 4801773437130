import InputLabel from '@/admin/components/InputLabel'
import { FormikArrayRenderArgs } from '@/admin/components/elements/FormikArray'
import { globalStyles } from '@/constants/Styles'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useDeviceSize } from '@/hooks/useLayout'
import { ToolTips } from '@components'
import { Button, ButtonClear, ErrorText, FormInput, FormMoneyInput, FormPickerInput, formStyles } from '@elements'
import { nonEmptyString } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { findPriceForAppMode } from '@helpers/products'
import { isMoney } from '@models/Money'
import { DefaultCatalog, ProductType } from '@models/Product'
import { useMemo } from 'react'
import { View } from 'react-native'
import { AdvancedPricingForm, ExtendedPricingForm, useStyles } from './AdvancedPricing'
import { buildBuyingOption, buildPrice, getSinglePrice, toPriceCatalog } from './AdvancedPricing-helpers'
import { formatCatalog } from '@helpers/wholesale'

/** This component is rendered for each buying option */
export function RenderBuyingOption({ formik, arrayHelpers, index, formValues, firstRenderIsDone }: RenderItemProps) {
  const { values: boValues, handleChange, showSimpleError, setFieldValue, handleBlur, errors } = formik
  const isFirst = index === 0
  const { isLargeDevice } = useDeviceSize()
  // This will tell app in what conditions to show the label for AdvancedPricingComponent
  const showLabel = formValues.catalog !== DefaultCatalog.WholesaleRetail ? isFirst || !isLargeDevice : true
  const canDeleteBo = formValues.buyingOptions.length > 1

  const styles = useStyles()

  /** When multiplier changes on a unit stock product, will recalculate prices based on the price per unit and new multiplier */
  useFocusFx(() => {
    if (!firstRenderIsDone || !formValues.unitStock || !isMoney(formValues.pricePerUnit)) {
      // Should only run after first render, on unitStock products
      return
    }
    const multiplier = boValues.multiplier || 0

    const newAmount = MoneyCalc.multiply(formValues.pricePerUnit, multiplier)

    if (formValues.catalog !== DefaultCatalog.WholesaleRetail) {
      const price = getSinglePrice(boValues, formValues.catalog, formValues.unitStock)

      setFieldValue('prices', [{ ...price, amount: newAmount }])
    } else {
      setFieldValue(
        'prices',
        boValues.prices.map((pr) => ({ ...pr, amount: newAmount })),
      )
    }

    // Only listen multiplier change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boValues.multiplier])

  const priceLabel = useMemo(
    () => (
      <InputLabel
        label={`Price${formValues.unitStock ? ' (calculated-editable)' : '*'}`}
        tooltipId={formValues.unitStock ? ToolTips.PRICE_OPTION : ToolTips.PRICE_GLOBAL}
        tooltipTitle="Price"
      />
    ),
    [formValues.unitStock],
  )

  return (
    <View key={`units[${index}]`}>
      {formValues.type === ProductType.FarmBalance ? (
        <View style={globalStyles.flexRow}>
          <View style={styles.buyingOptionContainer}>
            <FormMoneyInput
              // This component uses prices[0] because a farm-balance product is expected to be retail only so there would be a single price
              value={boValues.prices[0].amount}
              label={isFirst ? <InputLabel label="Credit options" required /> : null}
              onChangeText={async (value) => {
                await Promise.all([
                  setFieldValue('multiplier', 1),
                  setFieldValue('prices[0].amount', value),
                  setFieldValue('name', value ? `$${value.value / 100}` : ''),
                ])
              }}
              errorMessage={showSimpleError('prices[0].amount')}
              onBlur={handleBlur('prices[0].amount')}
            />
          </View>
          <ButtonClear
            disabled={!canDeleteBo}
            icon="times"
            size={14}
            onPress={() => {
              arrayHelpers.remove(index)
            }}
          />
        </View>
      ) : (
        <>
          <View style={globalStyles.flexRow}>
            <View style={styles.buyingOptionContainer}>
              <FormInput
                row
                placeholder="1 Pound"
                label={
                  showLabel ? <InputLabel label="Buying Option Name" tooltipId={ToolTips.OPTION_NAME} required /> : null
                }
                onChangeText={handleChange('name')}
                value={boValues.name}
                errorMessage={showSimpleError('name')}
                onBlur={handleBlur('name')}
              />
              <FormInput
                row
                placeholder="1"
                label={showLabel ? <InputLabel label="Units Each" tooltipId={ToolTips.OPTION_UNIT} required /> : null}
                value={boValues.multiplier.toString()}
                onChangeText={handleChange('multiplier')}
                errorMessage={showSimpleError('multiplier')}
                onBlur={handleBlur('multiplier')}
              />
              {formValues.catalog !== DefaultCatalog.WholesaleRetail && (
                // For either wholesale or retail only, we show a single price input
                <FormMoneyInput
                  row
                  // It's OK to use prices[0] here because there should be a single price if it's not 'Wholesale-Retail'
                  value={boValues.prices[0].amount}
                  label={showLabel ? priceLabel : null}
                  onChangeText={(value) => {
                    setFieldValue('prices[0].amount', value)
                  }}
                  errorMessage={showSimpleError('prices[0].amount')}
                  onBlur={handleBlur('prices[0].amount')}
                />
              )}
              <FormInput
                row
                placeholder="123456"
                label={showLabel ? <InputLabel label="SKU" tooltipId={ToolTips.OPTION_SKU} /> : null}
                onChangeText={handleChange('sku')}
                value={boValues.sku}
                errorMessage={showSimpleError('sku')}
                onBlur={handleBlur('sku')}
              />
              {!!formValues.unitStock && (
                <FormInput
                  row
                  onChangeText={handleChange('quantity')}
                  value={boValues.quantity ? boValues.quantity.toString() : ''}
                  placeholder="Eg. 10"
                  label={showLabel ? <InputLabel label="In Stock" tooltipId={ToolTips.IN_STOCK} required /> : null}
                  errorMessage={showSimpleError('quantity')}
                  onBlur={handleBlur('quantity')}
                />
              )}
            </View>
            <ButtonClear icon="times" disabled={!canDeleteBo} size={14} onPress={() => arrayHelpers.remove(index)} />
          </View>

          {!!errors.prices && (
            <ErrorText>
              {nonEmptyString(errors.prices) ? errors.prices : 'There is a problem with the prices'}
            </ErrorText>
          )}
          {/* Price per catalog */}
          {formValues.catalog === DefaultCatalog.WholesaleRetail && (
            <>
              {boValues.prices.map((pr, prIx, prices) => (
                <View style={globalStyles.flexRow} key={`price[${pr.id}]`}>
                  <View style={styles.buyingOptionContainer}>
                    <View style={[globalStyles.flex1, formStyles.inputStyle]} />
                    <FormPickerInput
                      row
                      onValueChange={(v) => {
                        setFieldValue(`prices[${prIx}].priceGroup`, { type: 'default-catalog', catalog: v })
                      }}
                      label="Price Group"
                      value={pr.priceGroup?.type === 'default-catalog' ? pr.priceGroup.catalog : ''}
                      items={[DefaultCatalog.Retail, DefaultCatalog.Wholesale].map((v) => ({
                        value: v,
                        label: formatCatalog(v),
                      }))}
                      errorMessage={showSimpleError(`prices[${prIx}].priceGroup`)}
                      onBlur={handleBlur(`prices[${prIx}].priceGroup`)}
                    />
                    <FormMoneyInput
                      row
                      value={pr.amount}
                      label={priceLabel}
                      onChangeText={(val) => {
                        setFieldValue(`prices[${prIx}].amount`, val)
                      }}
                      errorMessage={showSimpleError(`prices[${prIx}].amount`)}
                      onBlur={handleBlur(`prices[${prIx}].amount`)}
                    />
                  </View>
                  <ButtonClear
                    icon="times"
                    disabled={boValues.prices.length === 1}
                    size={14}
                    onPress={() => {
                      setFieldValue(
                        `prices`,
                        prices.filter((prInner) => prInner.id !== pr.id),
                      )
                    }}
                  />
                </View>
              ))}
              {boValues.prices.length < 2 && (
                <ButtonClear
                  title="+ Add price"
                  size={14}
                  style={styles.addPrice}
                  onPress={() => {
                    const hasRetail = !!findPriceForAppMode(boValues.prices, false)
                    const newPrice = buildPrice(hasRetail ? DefaultCatalog.Wholesale : DefaultCatalog.Retail)
                    setFieldValue(`prices`, [...boValues.prices, newPrice])
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      {index === formValues.buyingOptions.length - 1 && (
        <Button
          title="Add buying option"
          icon="plus"
          outline
          style={styles.addBuyingOpt}
          onPress={() =>
            arrayHelpers.push(
              buildBuyingOption({
                unitStock: !!formValues.unitStock,
                catalog: toPriceCatalog(formValues.catalog),
              }),
            )
          }
        />
      )}
    </View>
  )
}
export type RenderItemProps = {
  formik: FormikArrayRenderArgs<AdvancedPricingForm, 'buyingOptions'>['formik']
  arrayHelpers: FormikArrayRenderArgs<AdvancedPricingForm, 'buyingOptions'>['arrayHelpers']
  index: number
  firstRenderIsDone: boolean
  formValues: ExtendedPricingForm
}
