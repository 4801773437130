import env from '@/config/Environment'
import { Logger } from '@/config/logger'
import { GoogleAddressParser } from '@/constants/GoogleAddressParser'
import { useApiFx } from '@/hooks/useApiFx'
import { useCoords } from '@/hooks/useCoords/useCoords'
import { SearchScreenParams } from '@/navigation/types'
import { setSearchLocation } from '@/redux/actions/appState'
import { userSelector } from '@/redux/selectors'
import { getParsedAddressFromCoords } from '@api/Addresses'
import { Establishment } from '@components'
import { CoordString, getCoordString, parseCoordString, validCoords } from '@helpers/coordinate'
import { dequal } from '@helpers/customDequal'
import { formatAddress } from '@helpers/display'
import { extendErr } from '@helpers/helpers'
import { Coordinate } from '@models/Coordinate'
import { UserAddress } from '@models/UserAddress'
import { MutableRefObject } from 'react'
import { useSelector } from 'react-redux'
import { Dispatch } from 'redux'

export type LocTypeDisplay = 'Delivery' | 'Pickup' | 'Shipping'

export type LocType = 'zip' | 'state' | 'coord'

export const locTypeToDisplayMap: Record<LocType, LocTypeDisplay> = {
  coord: 'Pickup',
  state: 'Shipping',
  zip: 'Delivery',
}

type ParamsForInitialAddr = Pick<SearchScreenParams, 'center' | 'region'>

/** Provides the string that will be used as initial value in the google places search input */
export const useInitialAddress = ({
  condition = true,
  currentParams: params,
}: {
  /** While false the effect won't run and the loading state will remain false */
  condition?: boolean
  /** The current navigation parameters state when running in the search screen */
  currentParams?: ParamsForInitialAddr
} = {}) => {
  const coords = useCoords(params?.center)
  const { address: userAddr } = useSelector(userSelector)

  return useApiFx(
    async (coords: Coordinate, userAddr?: UserAddress, params?: ParamsForInitialAddr) => {
      // If the params include an existing region, the initial address should be it
      if (params?.region) {
        return params.region
      }

      // If the params include an existing center, the initial address should be reverse geocoded
      if (params?.center) {
        const coordsFromParams = parseCoordString(params.center)
        if (!coordsFromParams) {
          // This would mean the coords param is bad
          return ''
        }
        const parser = await getParsedAddressFromCoords(coordsFromParams)
        return formatAddress(parser.getAddress())
      }

      // If the coords selected are equal to those in the user address it should not reverse-geocode the coordinates. Just return the formatted user address
      if (userAddr && dequal(coords, userAddr?.coordinate)) {
        return formatAddress(userAddr)
      }

      // In this scenario the coords are being determined by the last search location or the current device location. The coords will be reverse geocoded
      const parser = await getParsedAddressFromCoords(coords)
      return formatAddress(parser.getAddress())
    },
    [coords, userAddr, params],
    !!coords && condition,
    {
      initialState: { data: undefined },
      failedConditionMode: 'stop-loading',
      // This should only run once because we don't want a new initial address to be set when we clear the address input
      once: true,
      noRefocus: true,
    },
  )
}

type SubmitLogisticsReturn = Promise<{ status: 'error' | 'done' | 'empty' }>

/** Handles submitting logistics options for the current logistics type, the current address in the google places search input, and the current query.
 * - This should be called when we want to submit all the current values, whether the establishment is known or not.
 */
export async function submitLogistics({
  locType,
  query,
  setErrorStr,
  dispatch,
  handleParams,
  submitCurrentAddress,
  onPlaceNotFound = 'doNothing',
}: {
  locType: LocType
  query?: string
  setErrorStr: (errorStr: string) => void
  dispatch: Dispatch<any>
  handleParams: (params: SearchScreenParams) => void
  submitCurrentAddress: MutableRefObject<(() => Promise<Establishment | undefined>) | undefined>
  /** If the input is empty or the address was invalid there will be no google place found. If the value is 'handleParams' it will run the handleParams handler. That's what we want in the search screen because updates should happen immediately on change. We don't want that in the homescreen because it has no params and instead it navigates to the search screen when logistics are all filled in */
  onPlaceNotFound?: 'handleParams' | 'doNothing'
}): SubmitLogisticsReturn {
  if (!submitCurrentAddress.current) {
    // This is never expected to happen because the ref will receive the handler on mount
    Logger.error(new Error('The submitCurrentAddress ref is undefined'))
    return { status: 'error' }
  }

  // This ensures the first autocomplete item is selected whenever the user presses the submit button
  const place = await submitCurrentAddress.current().catch((err) => {
    Logger.error(err)
    return undefined
  })

  if (!place) {
    // This might mean perhaps the current text did not produce any results, or the request for the details item inside google places search might've failed. Either of those things isn't meant to be handled by this component
    if (onPlaceNotFound === 'handleParams') {
      // In this case we want to update the params
      // It should only set the locType param, and reset the other params related to logistics because it would mean the user simply wants to switch the type while the input is empty
      handleParams({ locType, region: undefined, center: undefined, radius: undefined })
    }

    return { status: 'empty' }
  }

  return submitEstablishment({ place, locType, query, setErrorStr, dispatch, handleParams })
}

/** Handles setting the logistics options with a supplied establishment.
 * - This should be used once the establishment is known
 */
export async function submitEstablishment({
  place,
  locType,
  query,
  setErrorStr,
  dispatch,
  handleParams,
}: {
  place: Establishment
  locType: LocType
  query?: string
  setErrorStr: (errorStr: string) => void
  dispatch: Dispatch<any>
  handleParams: (params: SearchScreenParams) => void
}): SubmitLogisticsReturn {
  // Get the necessary data from the place
  let error = ''
  let center: CoordString | undefined = undefined
  let region: string | undefined = undefined

  switch (locType) {
    case 'coord': {
      const coords = place.coordinate
      const coordString = getCoordString(coords)
      if (!validCoords(coords) || !coordString) {
        error = 'No valid coordinates could be obtained from the selected location'
      } else {
        center = coordString
      }
      break
    }
    case 'state': {
      if (!place.address_components) {
        error = 'Could not obtain the data for this address'
      } else {
        try {
          const parser = new GoogleAddressParser(place.address_components)

          region = parser.result().state
        } catch (err) {
          Logger.error(err)
          error = 'Something went wrong while parsing the address'
        }
      }

      break
    }
    case 'zip': {
      if (!place.address_components) {
        error = 'Could not obtain the data for this address'
      } else {
        try {
          const parser = new GoogleAddressParser(place.address_components)

          region = parser.result().postal_code
        } catch (err) {
          Logger.error(err)
          error = 'Something went wrong while parsing the address'
        }
      }
      break
    }
  }

  // Validate the data
  if (center && region && env.APP_ENV === 'dev') {
    throw new Error('Only the center or the region should be defined. Not both.')
  }
  if (error && (center || region) && env.APP_ENV === 'dev') {
    throw new Error("If there's an error the params should be undefined")
  }

  if (error || (!center && !region)) {
    // This means the preconditions are not met to perform a search

    if (error) {
      // If there was an error above, we're done
      setErrorStr(error)
      return { status: 'error' }
    }

    // If there was no error try to get the missing data about the place. This might happen when they select a place that includes no zip code. For example when they search by only a city name, sometimes the address_components do not include the data for the zip code. But it can stil be obtained via the coords
    try {
      const parser = await getParsedAddressFromCoords(place.coordinate)
      if (locType === 'zip') {
        region = parser.result().postal_code
      } else if (locType === 'state') {
        region = parser.result().state
      }
    } catch (error) {
      Logger.error(error)
    }

    // check again to see if we got the data
    if (!center && !region) {
      setErrorStr(
        `The ${
          locType === 'zip'
            ? 'zip code'
            : locType === 'state'
            ? 'state'
            : locType === 'coord'
            ? 'coordinates'
            : 'necessary data'
        } could not be obtained. Please try a different address${
          locType === 'zip' ? ' that includes zip code.' : locType === 'state' ? ' that includes state.' : '.'
        }`,
      )
      return { status: 'error' }
    }
  }

  // Set the selected location as the most recent search location
  let city: string | undefined = undefined
  try {
    city = place.address_components ? new GoogleAddressParser(place.address_components).result().city : undefined
  } catch (error) {
    Logger.error(extendErr(error, 'Could not parse the city from the selected logistics address.'))
  }
  dispatch(setSearchLocation({ coordinate: place.coordinate, city }))

  const params: SearchScreenParams = { locType, center, region }
  if (query) params.q = query
  if (locType !== 'coord') {
    // The goal here is to reset any current value that may be in the radius param state
    // because radius only applies to searching by coordinates.
    params.radius = undefined
  }

  handleParams(params)

  return { status: 'done' }
}
