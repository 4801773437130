import { getPaymentSchedules, getPickups, isAvailablePickup } from '@helpers/order'
import { isLocalPickup, isNonPickup, isNonPickupDistLocation } from '@models/Location'
import { CartItem } from '@models/Order'
import {
  PayInFull,
  hasUnitStock,
  hasUnits,
  isGlobalStandard,
  isGlobalUnit,
  isPayInFull,
  isShare,
} from '@models/Product'
import { InvalidArgumentError } from '@shared/errors/cart'
import { DateTime } from 'luxon'

import { bullet } from '@helpers/display'
import { getAddressErrors } from '@models/Address'
import { createId } from '../helpers'
import { isCompatibleLocation, isSameAddress } from '../location'
import { getProductUnit } from '../products'
import { isValidDistro } from '../schedule'
import { isBefore } from '../time'

/** The pieces necessary to assemble a new cart item, without any type constraints or subtypes */
export type CartItemFields<T extends CartItem = CartItem> = { product: T['product'] } & Partial<
  Pick<T, 'distribution' | 'unit' | 'price' | 'csa' | 'pickups' | 'paymentSchedule'>
>

/** Create a new cart-item from its parts, for the purpose of adding to cart.
 *
 * Throws errors if the pieces do not make a valid cart item
 * */
export const buildCartItem = ({
  product,
  distribution,
  unit,
  price,
  paymentSchedule: paySchedule,
  csa,
  //for shares, pickups are not selectable by the user, but this helper can receive pickups, validate them and pass them to the resulting cartitem
  pickups,
  isAdmin = false,
  isWholesale,
}: CartItemFields & {
  /** Admin mode allows "closed" schedules */
  isAdmin?: boolean
  /** Whether the app is in wholesale mode */
  isWholesale?: boolean
}): CartItem => {
  if (!product) throw new InvalidArgumentError('Missing product')
  let result: CartItem

  if (isShare(product)) {
    if (product.isHidden) throw new InvalidArgumentError('This product is hidden and cannot be added to the cart')
    if (!csa) throw new InvalidArgumentError("Couldn't find the CSA for this Share")
    if (csa.isHidden) throw new InvalidArgumentError('The CSA is hidden')
    if (!product.csa.includes(csa.id)) throw new InvalidArgumentError("This CSA id doesn't belong to this share")
    if (!distribution) throw new InvalidArgumentError("Couldn't find the distribution for this share")
    const distInProduct = product.distributions.find((d) => d.id === distribution.id)
    if (!distInProduct) throw new InvalidArgumentError('Distribution not available in product')
    if (distInProduct.isHidden || (!isAdmin && distInProduct.closed))
      throw new InvalidArgumentError('The selected schedule is not available')
    if (!isValidDistro(distInProduct)) throw new InvalidArgumentError('The schedule has invalid data')

    if (isNonPickupDistLocation(distInProduct.location)) {
      if (!distribution.location.address)
        throw new Error('A nonPickup location must have a delivery address for checkout')
      if (!isNonPickup(distribution.location))
        throw new Error("The item location is non-pickup but the schedule's location is for local-pickup")
      const addressErrs = getAddressErrors(distribution.location.address, { allowPO: false })
      if (addressErrs)
        throw new Error(
          'The delivery address is invalid' +
            Object.entries(addressErrs)
              .map(([k, v]) => ` ${bullet} ${k}: ${v}.\n`)
              .join(''),
        )

      distInProduct.location['address'] = distribution.location.address
      const isCompatible = isCompatibleLocation(
        distInProduct.location,
        distInProduct.location.type,
        distInProduct.location.address,
      )
      if (!isCompatible) {
        throw new Error("The item's delivery address is not within the delivery area")
      }
    } else {
      if (!isLocalPickup(distribution.location))
        throw new Error("The cart item's location is local-pickup but the schedule's location is non-pickup")

      if (!isSameAddress(distInProduct.location.address, distribution.location.address))
        throw new Error("There is a mismatch between the item's pickup address and the schedule's address")
    }

    if (paySchedule && paySchedule.frequency === 'PER-PICKUP')
      throw new InvalidArgumentError('Shares cannot use a per-pickup payment schedule')
    //Will pre-select the 1st paySchedule if not specified in options.
    const paymentSchedule = paySchedule ?? getPaymentSchedules({ product, price, pickups })[0]
    if (paymentSchedule.frequency === 'PER-PICKUP')
      throw new InvalidArgumentError('Per-pickup payment schedules are only allowed for standard products')
    if (pickups) {
      if (!pickups.length) throw new InvalidArgumentError('Argument "pickups" is empty')
      if (
        pickups.some((d) => isBefore(d, DateTime.now(), { granularity: 'day', zone: distInProduct.location.timezone }))
      )
        throw new InvalidArgumentError('Some pickup dates are in the past')

      if (!isAdmin && pickups.some((d) => !isAvailablePickup(d, distInProduct)))
        throw new InvalidArgumentError('Some pickup dates are no longer available for this schedule')
    }

    result = {
      id: createId(),
      quantity: 1,
      product,
      distribution,
      csa,
      paymentSchedule,
      pickups:
        pickups ??
        getPickups(distribution, product, {
          excludeClosedDistros: !isAdmin,
          ignoreOrderCutoffWindow: isAdmin,
        }),
    }
  } else if (hasUnitStock(product)) {
    if (product.isHidden) throw new InvalidArgumentError('This product is hidden and cannot be added to the cart')
    if (!unit) throw new InvalidArgumentError('Unit is missing')
    if (!getProductUnit(product, unit.id)) throw new InvalidArgumentError('unit not available in the product')
    if (!price) throw new InvalidArgumentError('Could not find the price for this Standard product')
    if (isGlobalUnit(unit)) throw new InvalidArgumentError('UnitStandard products must have a unit stock in cartitem')
    if (!distribution) throw new InvalidArgumentError("Couldn't find the distribution for this standard product")
    const distInProduct = product.distributions.find((d) => d.id === distribution.id)
    if (!distInProduct) throw new InvalidArgumentError('Distribution not available in product')
    if (distInProduct.isHidden || (!isAdmin && distInProduct.closed))
      throw new InvalidArgumentError('The selected schedule is not available')
    if (!isValidDistro(distInProduct)) throw new InvalidArgumentError('The schedule has invalid data')

    if (isNonPickupDistLocation(distInProduct.location)) {
      if (!distribution.location.address)
        throw new Error('A nonPickup location must have a delivery address for checkout')
      if (!isNonPickup(distribution.location))
        throw new Error("The item location is non-pickup but the schedule's location is for local-pickup")
      const addressErrs = getAddressErrors(distribution.location.address, { allowPO: false })
      if (addressErrs)
        throw new Error(
          'The delivery address is invalid' +
            Object.entries(addressErrs)
              .map(([k, v]) => ` ${bullet} ${k}: ${v}. \n`)
              .join(''),
        )

      distInProduct.location['address'] = distribution.location.address
      const isCompatible = isCompatibleLocation(
        distInProduct.location,
        distInProduct.location.type,
        distInProduct.location.address,
      )
      if (!isCompatible) {
        throw new Error("The item's delivery address is not within the delivery area")
      }
    } else {
      if (!isLocalPickup(distribution.location))
        throw new Error("The cart item's location is local-pickup but the schedule's location is non-pickup")

      if (!isSameAddress(distInProduct.location.address, distribution.location.address))
        throw new Error("There is a mismatch between the item's pickup address and the schedule's address")
    }

    if (!pickups) throw new InvalidArgumentError('Standard products require an array of pickups in cart')
    if (!pickups.length) throw new InvalidArgumentError('Argument "pickups" has no data')
    if (pickups.length === 1 && paySchedule && !isPayInFull(paySchedule))
      throw new InvalidArgumentError('Standard products with a single pickup must have a pay-in-full payment schedule')
    if (pickups.some((d) => isBefore(d, DateTime.now(), { granularity: 'day', zone: distInProduct.location.timezone })))
      throw new InvalidArgumentError('Some pickup dates are in the past')

    if (!isWholesale && product.minPickups && pickups.length < product.minPickups)
      throw new InvalidArgumentError(`The product requires at least ${product.minPickups} pickups`)

    // If this is happening in the consumer side, should check that each pickup is after the cutoff date.
    if (!isAdmin) {
      if (pickups.some((d) => !isAvailablePickup(d, distInProduct)))
        throw new InvalidArgumentError('Some pickup dates are no longer available for this schedule')
    }

    if (paySchedule && (paySchedule.frequency === 'MONTHLY' || paySchedule.frequency === 'WEEKLY'))
      throw new InvalidArgumentError(
        'Wrong payment schedule type. You tried to add a standard product with a share payment schedule',
      )
    if (!!csa && csa.isHidden) throw new InvalidArgumentError('The CSA is hidden')
    if (!!csa && !product.csa?.includes(csa.id))
      throw new InvalidArgumentError("This CSA id doesn't belong to this standard product")

    result = {
      id: createId(),
      quantity: 1,
      product,
      distribution,
      csa,
      unit,
      price,
      paymentSchedule: (paySchedule as PayInFull) ?? (getPaymentSchedules({ product, price, pickups })[0] as PayInFull),
      pickups,
    }
  } else if (isGlobalStandard(product)) {
    if (product.isHidden) throw new InvalidArgumentError('This product is hidden and cannot be added to the cart')
    if (!unit) throw new InvalidArgumentError('Unit is missing')
    if (!getProductUnit(product, unit.id)) throw new InvalidArgumentError('unit not available in the product')
    if (!price) throw new InvalidArgumentError('Could not find the price for this Standard product')
    if (!isGlobalUnit(unit))
      throw new InvalidArgumentError('GlobalStandard products must have a unit of global stock in cartitem')
    if (!distribution) throw new InvalidArgumentError("Couldn't find the distribution for this standard product")
    const distInProduct = product.distributions.find((d) => d.id === distribution.id)
    if (!distInProduct) throw new InvalidArgumentError('Distribution not available in product')
    if (distInProduct.isHidden || (!isAdmin && distInProduct.closed))
      throw new InvalidArgumentError('The selected schedule is not available')
    if (!isValidDistro(distInProduct)) throw new InvalidArgumentError('The schedule has invalid data')

    if (isNonPickupDistLocation(distInProduct.location)) {
      if (!distribution.location.address)
        throw new Error('A nonPickup location must have a delivery address for checkout')
      if (!isNonPickup(distribution.location))
        throw new Error("The item location is non-pickup but the schedule's location is for local-pickup")
      const addressErrs = getAddressErrors(distribution.location.address, { allowPO: false })
      if (addressErrs)
        throw new Error(
          'The delivery address is invalid' +
            Object.entries(addressErrs)
              .map(([k, v]) => ` ${bullet} ${k}: ${v}. \n`)
              .join(''),
        )

      distInProduct.location['address'] = distribution.location.address
      const isCompatible = isCompatibleLocation(
        distInProduct.location,
        distInProduct.location.type,
        distInProduct.location.address,
      )
      if (!isCompatible) {
        throw new Error("The item's delivery address is not within the delivery area")
      }
    } else {
      if (!isLocalPickup(distribution.location))
        throw new Error("The cart item's location is local-pickup but the schedule's location is non-pickup")

      if (!isSameAddress(distInProduct.location.address, distribution.location.address))
        throw new Error("There is a mismatch between the item's pickup address and the schedule's address")
    }

    if (!pickups) throw new InvalidArgumentError('Standard products require an array of pickups in cart')
    if (!pickups.length) throw new InvalidArgumentError('Argument "pickups" has no data')
    if (pickups.length === 1 && paySchedule && !isPayInFull(paySchedule))
      throw new InvalidArgumentError('Standard products with a single pickup must have a pay-in-full payment schedule')
    if (pickups.some((d) => isBefore(d, DateTime.now(), { granularity: 'day', zone: distInProduct.location.timezone })))
      throw new InvalidArgumentError('Some pickup dates are in the past')

    if (!isWholesale && product.minPickups && pickups.length < product.minPickups)
      throw new InvalidArgumentError(`The product requires at least ${product.minPickups} pickups`)

    // If this is happening in the consumer side, should check that each pickup is after the cutoff date.
    if (!isAdmin) {
      if (pickups.some((d) => !isAvailablePickup(d, distInProduct)))
        throw new InvalidArgumentError('Some pickup dates are no longer available for this schedule')
    }

    if (paySchedule && (paySchedule.frequency === 'MONTHLY' || paySchedule.frequency === 'WEEKLY'))
      throw new InvalidArgumentError(
        'Wrong payment schedule type. You tried to add a standard product with a share payment schedule.',
      )
    if (!!csa && csa.isHidden) throw new InvalidArgumentError('The CSA is hidden')
    if (!!csa && !product.csa?.includes(csa.id))
      throw new InvalidArgumentError("This CSA id doesn't belong to this standard product")

    result = {
      id: createId(),
      quantity: 1,
      product,
      distribution,
      csa,
      unit,
      price,
      paymentSchedule: (paySchedule as PayInFull) ?? (getPaymentSchedules({ product, price, pickups })[0] as PayInFull),
      pickups,
    }
  } else if (hasUnits(product)) {
    if (product.isHidden) throw new InvalidArgumentError('This product is hidden and cannot be added to the cart')
    // This covers digital products, because they're the remaining products which use units
    if (!price) throw new InvalidArgumentError('Could not find the price for this digital product')
    if (!unit) throw new InvalidArgumentError('Unit is missing')
    if (!getProductUnit(product, unit.id)) throw new InvalidArgumentError('unit not available in the product')
    if (!isGlobalUnit(unit)) throw new InvalidArgumentError('Digital products must have a global stock unit in cart')
    if (!!csa && csa.isHidden) throw new InvalidArgumentError('The CSA is hidden')
    if (!!csa && !product.csa?.includes(csa.id))
      throw new InvalidArgumentError("This CSA id doesn't belong to this digital product")
    if (pickups) throw new InvalidArgumentError('Digital products should have no pickups')

    result = {
      id: createId(),
      quantity: 1,
      product,
      csa,
      unit,
      price,
      paymentSchedule: getPaymentSchedules({ product, price })[0] as PayInFull,
    }
  } else throw new InvalidArgumentError(`This product type is not implemented`)
  return result
}
