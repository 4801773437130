import { Text } from '@elements'
import { Feather } from '@expo/vector-icons'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { LogoResponsive } from '../../admin/components/LogoResponsive'
import { Divider } from '../../components/elements/Divider'
import { Touchable } from '../../components/elements/Touchable'
import WebLink from '../../components/elements/WebLink'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'

import { useDeviceSize } from '@/hooks/useLayout'
import { wholesaleSelector } from '@/redux/selectors'
import { memo } from 'react'
import { useSelector } from 'react-redux'

export const WebFooter = memo(function HomeFooter() {
  const { isSmallDevice } = useDeviceSize()
  const { isWholesale } = useSelector(wholesaleSelector)
  const styles = useStyles()

  return (
    <View style={[styles.wrapper, { backgroundColor: isWholesale ? Colors.purple : Colors.darkGreen }]}>
      <View style={styles.container}>
        <View style={styles.row}>
          {!isSmallDevice && <Divider style={styles.divider} />}
          <LogoResponsive white type="logo" style={globalStyles.padding10} />
        </View>
        <View style={styles.linkRow}>
          <View style={styles.linkBlock}>
            <Text style={styles.blockHeader}>Shop</Text>
            <WebLink style={styles.linkItem} url={isWholesale ? '/search' : '/explore'}>
              Find local farms
            </WebLink>
            <WebLink
              style={styles.linkItem}
              url="https://grownby.zohodesk.com/portal/en/newticket?departmentId=687475000000006907"
            >
              Shopper Support
            </WebLink>
          </View>

          <View style={styles.linkBlock}>
            <Text style={styles.blockHeader}>Sell</Text>
            <WebLink style={styles.linkItem} url="https://www.farmgenerations.coop/schedule">
              Schedule Demo
            </WebLink>
            <WebLink
              style={styles.linkItem}
              url="https://grownby.zohodesk.com/portal/en/newticket?departmentId=687475000000006907"
            >
              Farmer Support
            </WebLink>
          </View>

          <View style={styles.linkBlock}>
            <Text style={styles.blockHeader}>About</Text>
            <WebLink style={styles.linkItem} url="https://www.farmgenerations.coop/about">
              Our story
            </WebLink>
            <WebLink style={styles.linkItem} url="https://www.farmgenerations.coop/coop">
              We're a Co-op
            </WebLink>
            <WebLink style={styles.linkItem} url="https://www.farmgenerations.coop/about">
              News
            </WebLink>
            <WebLink style={styles.linkItem} url="https://www.farmgenerations.coop/team">
              Team
            </WebLink>
            <WebLink style={styles.linkItem} url="https://www.farmgenerations.coop/contact">
              Contact
            </WebLink>
          </View>

          {isSmallDevice && <Divider clear />}
        </View>
        <View style={styles.iconRow}>
          <Text color={Colors.white} style={styles.marginTop}>
            &copy; {new Date().getFullYear()} All Rights Reserved
          </Text>
          <View style={styles.row}>
            <Touchable url="https://www.facebook.com/farmgenerationscoop/">
              <Feather name="facebook" style={styles.socialIcon} />
            </Touchable>
            <Touchable url="https://www.instagram.com/farmgenerationscoop/">
              <Feather feather name="instagram" style={styles.socialIcon} />
            </Touchable>
            <Touchable url="https://www.youtube.com/channel/UCKrBUx-SIh_g2fHLT2JJiaA">
              <Feather name="youtube" style={styles.socialIcon} />
            </Touchable>
            <Touchable url="https://www.linkedin.com/company/farm-generations/">
              <Feather name="linkedin" style={styles.socialIcon} />
            </Touchable>
          </View>
        </View>
      </View>
    </View>
  )
})

const useStyles = CreateResponsiveStyle(
  {
    wrapper: {
      alignItems: 'center',
    },
    container: {
      padding: 30,
      width: '100%',
      maxWidth: 1440,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      marginHorizontal: 10,
    },
    iconRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginHorizontal: 30,
    },
    linkRow: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginLeft: '10%',
    },
    linkBlock: {
      marginHorizontal: 30,
    },
    blockHeader: {
      fontWeight: 'bold',
      marginVertical: 20,
      color: Colors.white,
      fontSize: 16,
    },
    linkItem: {
      marginVertical: 10,
      color: Colors.white,
    },
    socialIcon: {
      color: Colors.white,
      fontSize: 25,
      paddingHorizontal: 10,
    },
    divider: {
      flex: 1,
      marginHorizontal: 30,
    },
    marginTop: {
      marginTop: 10,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      container: {
        padding: 10,
      },
      linkRow: {
        flexDirection: 'column',
        marginLeft: 10,
      },
      iconRow: {
        flexDirection: 'column-reverse',
      },
      blockHeader: {
        marginBottom: 10,
      },
      linkItem: {
        marginVertical: 7,
        textDecorationLine: 'underline',
      },
    },
  },
)
