/** Constants containing retail urls*/
export const RETAIL_URLS = {
  local: 'http://localhost:19006/',
  dev: 'https://grownby-dev.web.app/',
  staging: 'https://grownby-staging.web.app/',
  beta: 'https://grownby-beta.web.app/',
  prod: 'https://grownby.app/',
}

/** Constants containing wholesale urls*/
export const WHOLESALE_URLS = {
  local: 'http://localhost:19006/',
  dev: 'https://grownby-dev-wholesale.web.app/',
  staging: 'https://grownby-staging-wholesale.web.app/',
  beta: 'https://grownby-beta-wholesale.web.app/',
  prod: 'https://wholesale.grownby.app/',
}

type Options = {
  isWholesale: boolean | undefined
}

/** Will return the GrownBy App url for the given options */
export function getBaseUrl(env: 'dev' | 'staging' | 'beta' | 'prod' | 'local', { isWholesale }: Options) {
  const URL_SET = isWholesale ? WHOLESALE_URLS : RETAIL_URLS
  return URL_SET[env]
}
