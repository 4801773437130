import { ArrElement, Printable } from '@helpers/typescript'

import { ShortState } from '@/assets/data/states'
import { isObject, nonEmptyString } from '@helpers/helpers'
import { Address, ShortZip } from './Address'
import { Distribution } from './Distribution'
import { Farm, FarmStatus } from './Farm'
import { InvoiceStatus } from './Invoice'
import { DefaultCatalog, Product, ProductType, Standard, UnitProduct } from './Product'

export interface AlgoliaDocument {
  /** The objectID of a document is the id by which algolia manages operations on the index. It is meant to be unique per index, unlike our custom "id" property which refers to a database id */
  objectID: string
}

/** A static name of each algolia index type */
export enum AlgoliaIndexType {
  geosearch = 'geosearch',
  admindata = 'admindata',
}

/** These are different kinds of algolia documents shared over the various indices */
export enum AlgoliaDocType {
  FARM = 'farm',
  PRODUCT = 'product',
  DISTRO = 'distro',
  ORDER = 'order',
  INVOICE = 'invoice',
  CUSTOMER = 'customer',
}

/** An algolia document for the geosearch index */
export type AlgoliaGeoDoc<T = AlgoliaGeoFarm | AlgoliaGeoProduct | AlgoliaGeoDistro> = T & {
  index: AlgoliaIndexType.geosearch
  /** The default catalog for the geosearch document. There will be a dedicated document for each default catalog the data belongs to. If the data belongs to both wholesale & retail, there will be two documents, otherwise there will be a single one */
  defaultCatalog: DefaultCatalog.Wholesale | DefaultCatalog.Retail
  /** The objectId is an algolia-specific document id. In the case of a product it is a concatenation of the catalog, the distro id, and the product id. In all other cases it is the catalog followed by the db id */
  objectID: T extends AlgoliaGeoProduct
    ?
        | `${AlgoliaGeoDoc['defaultCatalog']}_${Distribution['id']}_${Product['id']}`
        | `${DefaultCatalog.Retail}_digital_${Product['id']}`
    : `${AlgoliaGeoDoc['defaultCatalog']}_${(Distribution | Farm)['id']}`
  /** The id is the db id */
  id: string
  docType: AlgoliaDocType
  name: string
  description: string
  images: string[]
  imageSort: number
  isHidden?: boolean
  address: Omit<Address, 'coordinate'>
  /** The specific geo location for the document.
   * - In physical geo products for a pickup schedule, this will be the schedule's location coordinates.
   * - In physical geo products for a non pickup schedule, this will be undefined. It needs to be undefined because if the farm coords are used as default, it would provide misleading search results when somebody wants to find specifically products for pickup at a given location.
   * - In digital geo products this will have the farm address coordinates
   * - In geo farms this will have the farm address coords
   * - In geo distros with pickup location this will have the schedule location coords
   * - In geo distros with non pickup location this will be undefined */
  _geoloc?: {
    lat: number
    lng: number
  }
  /** Will be true if the product is either EBT-eligible or EBT-only.
   * Only relevant for standard products (default undefined for non-standard products) */
  isEbt: boolean
  /** The 'farm' property inside a geo doc is meant to include only fields about the farm which we want to have in all geodoc types, for example geoProduct, geoDistro, geoFarm. Other farm properties should only go inside the geoFarm. Also fields should not be in both places. */
  farm: {
    id: string
    name: string
    about: string
    status: FarmStatus
    logo?: string
    practices: string[]
    tags: string[]
    reviews?: {
      numRatings: number
      rating: number
    }
    hasProducts: boolean
    setupCount: number
    productCount: number
    urlSafeSlug: string
    isWholesale: boolean
    /** Since this value comes from the farm model, and it may have a catalog-specific value, the value in this field will be based on the catalog the document is for */
    orderMin: number
    /** Since this value comes from the farm model, and it may have a catalog-specific value, the value in this field will be based on the catalog the document is for */
    dueDateTolerance?: number
  }
}

export type AlgoliaGeoFarm = {
  locationCount: number
}

export type AlgoliaGeoDistro = {
  scheduleText: string
  distroNickname: string
  locationId: string
}

/** A product document subtype for the geosearch index. Each document combines the data of a product and one of its schedules */
export type AlgoliaGeoProduct = Pick<AlgoliaGeoDistro, 'distroNickname' | 'scheduleText'> & {
  category: string[]
  /** a price string used when the products are viewed in the map cards. This price is based on the document's catalog */
  priceInMap: string
  /** The price string to use in the product card in the shopping screens. This should match the logic used to get this price inside the card for a db product. This price is based on the document's catalog */
  priceInCard: string
  /** Price numeric value that serves as a numeric range filter. This price is based on the document's catalog */
  priceFilter: number | null
  shortDescription: Product['description']
  type: Product['type']
  /**Timestamp in miliseconds. After this timestamp the product has no pickups available. Digital products have a timestamp in the far distant future, while invalid data will get zero. */
  lastAvailStamp: number
  /** Whether the stock is greater than zero */
  isInStock: boolean
  /** isPrivate is meant to encode all logic about a product's privacy status into a boolean that has no external dependencies.
   * - If the product belongs to only private CSAs isPrivate will be true; Except if it's a Standard or Digital product it also needs to have the "hideFromShop" setting to true, to be considered private in this sense. */
  isPrivate: boolean
  /** A copy of the hideFromShop field. If non Standard or Digital, will be false because this field exists only for non shares. */
  hideFromShop: NonNullable<UnitProduct['hideFromShop']>
  /** This inherits the product field that holds the array of csa ids */
  csa: NonNullable<Product['csa']>
  /** The product's locations. Empty for non-physical. The locations included will be only those compatible with the document's catalog */
  locations: {
    id: string
    name: string
  }[]
  /** GeoProducts for NonPickup schedules will have the delivery/ shipping regions here. (Either the zipcodes or the states)
   * - If this document is for a Pickup schedule, it will have an array with 'None' as a region. The reason for this design is it allows us to filter documents that have any regions. This would be useful when the user clears the region parameter but is still filtering by delivery for example. Otherwise if the value were undefined, algolia filters syntax has no way to check for whether the value is defined or not. */
  regions: ShortZip[] | ShortState[] | 'None'[]
  urlSafeSlug: string
  /** Whether the product is featured. Featured prods are highlighted on FarmShop screen*/
  isFeatured: Product['isFeatured']
  /** Whether the product is EBT only. Look at EbtEligibility documentation for more info. */
  isEbtOnly: boolean
}

export type AlgoliaAdminDoc = {
  index: AlgoliaIndexType.admindata
  objectID: string
  id: string
  docType: AlgoliaDocType
  farmId: string
}

/*
  Lists of order metadata that can be used for filtering
  date represents when the order was placed
 */

export type AlgoliaAdminMetaArr = {
  id: string
  name: string
  date: number
}[]

export type AlgoliaAdminMeta = {
  csas: AlgoliaAdminMetaArr
  locations: AlgoliaAdminMetaArr
  distributions: AlgoliaAdminMetaArr
}

export type AlgoliaAdminOrderMeta = AlgoliaAdminMeta & {
  products: AlgoliaAdminMetaArr
}

export type AlgoliaAdminCustomer = AlgoliaAdminMeta &
  AlgoliaAdminDoc & {
    docType: AlgoliaDocType.CUSTOMER
    name: string
    email: string
    phone: string
    alternatePhoneNums: string[]
    farmCredit: number
    monthlyReloadAmt: number
  }

export type AlgoliaAdminOrder = AlgoliaAdminOrderMeta &
  AlgoliaAdminDoc & {
    docType: AlgoliaDocType.ORDER
    /** This id will either be an Order ID or a Draft Order ID */
    id: string
    user: {
      id: string
      name: string
      email: string
    }
    orderNum: number
    alternateOrderNums: string[]
    date: number
    total: number
    status: string
    isDraft: boolean
    isWholesale: boolean
  }

export type AlgoliaAdminInvoice = AlgoliaAdminDoc & {
  docType: AlgoliaDocType.INVOICE
  status: InvoiceStatus
  user: {
    id: string
    name: string
    email: string
  }
  order: {
    id: string
    orderNum?: number
  }
  invoiceNum?: number
  amountDue: number
  payUrl?: string
  pdf?: string
  date: number
  paymentMethods: string[]
  paymentMethodsDisplay: string[]
}

export type AlgoliaAdminProduct = AlgoliaAdminDoc &
  AlgoliaAdminMeta & {
    docType: AlgoliaDocType.PRODUCT
    type: ProductType
    unitStock: Standard['unitStock']
    name: string
    description?: string
    longDescription?: string
    /** This sku is only for display purposes. For shares, it's the main sku. For standard products it's the unitSkuPrefix */
    skuDisplay: string
    productionMethod: string
    isHidden: boolean
    isFeatured: boolean
    category: string[]
    /** The timestamp at which the product is last available for ordering. Not catalog-specific */
    lastAvailStamp: number
    urlSafeSlug: string
    /** Will be true if the product is either EBT-eligible or EBT-only.
     * Only relevant for standard products (default undefined for non-standard products) */
    isEbt: boolean
    /** Whether the product is EBT only. Look at EbtEligibility documentation for more info. */
    isEbtOnly: boolean
    image: string
    /** A price range string for the product. Not catalog specific */
    price: string
    pricePerUnit: UnitProduct['pricePerUnit']
    /** The result of getStock(), incldes stock for all catalogs */
    stock: number
    /** Display string for the availability dates. Not catalog-specific */
    availabilityDisplay: string
    /** The product's default catalog assignment */
    defaultCatalog: Standard['defaultCatalog']
  }

/** All permutations of a filter property and its valid FacetFilter values, in the proper format */
export type FacetFilterBase<Prop extends string = string, Values extends Printable = Printable> = `${Prop}:${
  | '-'
  | ''}${Values}`

export type HiddenFilter = FacetFilterBase<'isHidden', boolean>
export type GeoDocTypeFilter = FacetFilterBase<
  'docType',
  AlgoliaDocType.FARM | AlgoliaDocType.DISTRO | AlgoliaDocType.PRODUCT
>
export type FarmStatusFilter = FacetFilterBase<'farm.status', FarmStatus>
export type EbtFilter = FacetFilterBase<'isEbt', boolean>
export type ProductTypeFilter = FacetFilterBase<'type', ProductType>

/** Commonly used facet filters for convenience, including each of the filters that can be toggled in the Explore screen.
 * - The ones with a minus sign cannot be used as string filters because that requires the `AND NOT XXX` syntax */
/** The different kinds of filter strings used in the algolia geosearch index */
export type GeoFilter = GeoDocTypeFilter | FarmStatusFilter | HiddenFilter | EbtFilter

/** Enum that stores some commonly used GeoFilters for convenience, including each of the filters that can be toggled in the Explore screen.
 * - These literal enum members are expected to extend type `GeoFilter`
 * - Should be used as values for the `facetFilter` prop of an algolia index */
export enum FILTERS {
  Product = 'docType:product',
  Farm = 'docType:farm',
  Distro = 'docType:distro',
  /** This is intended to produce no results */
  NullDocType = 'docType:null',
  Registered = 'farm.status:Registered',
  NotRegistered = 'farm.status:-Registered',
  NotHidden = 'isHidden:-true',
  Ebt = 'isEbt:true',
  NotInactiveFarm = 'farm.status:-Inactive',
  NotPrivateProd = 'isPrivate:-true',
  NotAddon = 'type:-addon',
  // the farm.isWholesale field belongs to GeoDoc and its subtypes
  WholesaleFarm = 'farm.isWholesale:true',
  Wholesale = 'defaultCatalog:wholesale',
  Retail = 'defaultCatalog:retail',
}

/** This is meant to be a copy of the main FILTERS enum which expresses the same values in query filter syntax instead of facet filter syntax */
export enum FILTERS_QUERY {
  Product = 'docType:product',
  Farm = 'docType:farm',
  Distro = 'docType:distro',
  /** This is intended to produce no results */
  NullDocType = 'docType:null',
  Registered = 'farm.status:Registered',
  NotRegistered = 'NOT farm.status:Registered',
  NotHidden = 'NOT isHidden:true',
  Ebt = 'isEbt:true',
  NotInactiveFarm = 'NOT farm.status:Inactive',
  NotPrivateProd = 'NOT isPrivate:true',
  NotAddon = 'NOT type:addon',
  // the farm.isWholesale field belongs to GeoDoc and its subtypes
  WholesaleFarm = 'farm.isWholesale:true',
  Wholesale = 'defaultCatalog:wholesale',
  Retail = 'defaultCatalog:retail',
}

/** The FacetFilter type encodes the filtering information sent to algolia.
 * - Strings in the outer array are read as AND statements
 * - Strings in the inner arrays are read as OR statements
 * - Order matters: Filters are applied in the order they are defined within the array. The first filter should generally be the docType because it's the broadest. If you apply a hidden filter before narrowing by "docType:product", it might not apply correctly because some docTypes don't have a isHidden field */
export type FacetFilter<F extends FacetFilterBase = FacetFilterBase> = (F | F[])[]

/** A query filter is different from a facet filter in that the negation is expressed as a NOT instead of a minus sign, and is placed before the field name */
export type QueryFilterBase<Prop extends string = string, Values extends Printable = Printable> = `${
  | 'NOT '
  | ''}${Prop}:${Values}`

/** Similar to a facet filter, this is intended to express a query filter in array format */
export type QueryFilterArray<F extends QueryFilterBase = QueryFilterBase> = (F | F[])[]

type ExtractPrintables<T extends Printable | unknown> = Extract<T, Printable>

type FacetPathFilter<
  T extends Record<string, Printable | object>,
  K extends keyof T & string = keyof T & string,
> = T[K] extends Printable
  ? FacetFilterBase<K, T[K]>
  : T[K] extends Printable[]
  ? FacetFilterBase<K, ArrElement<T[K]>>
  : T[K] extends Record<string, Printable | object>
  ? FacetFilterBase<`${K}.${keyof T[K] & string}`, ExtractPrintables<T[K][keyof T[K]]>>
  : T[K] extends Record<string, Printable | object>[]
  ? FacetFilterBase<
      `${K}.${keyof ArrElement<T[K]> & string}`,
      ExtractPrintables<ArrElement<T[K]>[keyof ArrElement<T[K]>]>
    >
  : never

/** Adds type checking to a string meant to be a facet filter, using an object type as guidance. This is safer because it will show type errors if the object type ever changes. However it doesn't work for the "key.id: xxx" syntax because it needs to capture the keys of the inner objects. */
export const asFilter = <T extends Record<string, Printable | object>, K extends keyof T & string = keyof T & string>(
  filter: FacetPathFilter<T, K>,
) => filter

/** Type for the FacetFilter that controls the map results */
export type MapFilters = [
  GeoDocTypeFilter[],
  FarmStatusFilter[],
  FILTERS.Ebt[],
  FILTERS.NotHidden,
  FILTERS.NotInactiveFarm,
  FILTERS.NotPrivateProd,
]

/** These are the filters for the map search. Their value will change later, as user interacts with map filters */
export const initialFiltersMapSearch: MapFilters = [
  [FILTERS.Farm, FILTERS.Product, FILTERS.Distro],
  [FILTERS.Registered],
  [],
  FILTERS.NotHidden,
  FILTERS.NotInactiveFarm,
  FILTERS.NotPrivateProd,
]

export const isGeoDocTypeFilter = (filter: FacetFilterBase): filter is GeoDocTypeFilter => {
  const [prop, value] = filter.split(':')
  return (
    prop === 'docType' &&
    (value.endsWith(AlgoliaDocType.FARM) ||
      value.endsWith(AlgoliaDocType.DISTRO) ||
      value.endsWith(AlgoliaDocType.PRODUCT))
  )
}

export const isStatusFilter = (filter: FacetFilterBase): filter is FarmStatusFilter =>
  filter.split(':')[0] === 'farm.status'

export const isEbtFilter = (filter: FacetFilterBase): filter is EbtFilter => filter?.split(':')[0] === 'isEbt'

/** Identifies an object being a geodoc of any subtype */
export const isGeoDoc = (obj: unknown): obj is AlgoliaGeoDoc => isObject(obj) && nonEmptyString(obj.objectID)

export const isGeoFarm = (obj: unknown): obj is AlgoliaGeoDoc<AlgoliaGeoFarm> =>
  isGeoDoc(obj) && 'docType' in obj && obj.docType === AlgoliaDocType.FARM
export const isGeoDistro = (obj: unknown): obj is AlgoliaGeoDoc<AlgoliaGeoDistro> =>
  isGeoDoc(obj) && 'docType' in obj && obj.docType === AlgoliaDocType.DISTRO
export const isGeoProduct = (obj: unknown): obj is AlgoliaGeoDoc<AlgoliaGeoProduct> =>
  isGeoDoc(obj) && 'docType' in obj && obj.docType === AlgoliaDocType.PRODUCT

/** Identifies an admin doc */
export const isAdminDoc = (obj: unknown): obj is AlgoliaAdminDoc =>
  isObject(obj) && 'index' in obj && obj.index === AlgoliaIndexType.admindata && nonEmptyString(obj.objectID)

export const isAlgoliaAdminCustomer = (obj: unknown): obj is AlgoliaAdminCustomer =>
  isAdminDoc(obj) && 'docType' in obj && obj.docType === AlgoliaDocType.CUSTOMER
export const isAlgoliaAdminProduct = (obj: unknown): obj is AlgoliaAdminProduct =>
  isAdminDoc(obj) && 'docType' in obj && obj.docType === AlgoliaDocType.PRODUCT
export const isAlgoliaAdminOrder = (obj: unknown): obj is AlgoliaAdminOrder =>
  isAdminDoc(obj) && 'docType' in obj && obj.docType === AlgoliaDocType.ORDER

/** Identifies an algolia document of any type */
export const isAlgoliaDoc = (obj: unknown): obj is AlgoliaGeoDoc | AlgoliaAdminDoc => isAdminDoc(obj) || isGeoDoc(obj)

/**A numeric filter type as specified in https://www.algolia.com/doc/api-reference/api-parameters/numericFilters/ */
export type NumericFilterBase<Attr extends Printable> = `${Attr} ${'<' | '<=' | '=' | '!=' | '>=' | '>'} ${number}`

/**Adds type checking to a string that is meant to be a numeric filter */
export const asNumFilter = <T extends Record<string, number | unknown>, K extends keyof T & string>(
  filter: NumericFilterBase<K>,
) => filter

/** A product category that will be automatically applied to any algolia geo product which belongs to a CSA, regardless of the product type.
 * - For example a standard or digital product that belongs to a csa should have this category added automatically to the al
 * - All share types will belong to a csa so they will all get this category in algolia.
 */
export const AlgoliaShareCategory = 'CSA Products'
