import { CurrentLocation } from '@/constants/types'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import { HomeParamList } from '@/navigation/types'
import { setSearchLocation } from '@/redux/actions/appState'
import { searchLocationSelector, userLocationSelector } from '@/redux/selectors'
import { getParsedAddressFromCoords } from '@api/Addresses'
import { Establishment, useCreateSearchParams } from '@components'
import { LoadingView } from '@elements'
import { validCoords } from '@helpers/coordinate'
import { Optional } from '@helpers/typescript'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { NearbyProdsInput } from './NearbyProductsInput'
import { NearbyProductsResults } from './NearbyProductsResults'

/** Decides which location to use based on available locations */
const getInitialLocation = (searchLoc?: CurrentLocation, userLoc?: CurrentLocation | null) => {
  // If we have searched for a location that's preferred
  if (searchLoc?.coordinate && validCoords(searchLoc.coordinate)) return searchLoc
  // If not we use current location
  return userLoc && validCoords(userLoc.coordinate) ? userLoc : undefined
}

/** Contains everything related to the nearby products search in the homescreen */
export const NearbyProducts = memo(function NearbyProducts() {
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'HomeScreen'>>()
  const searchLoc = useSelector(searchLocationSelector)
  const userLoc = useSelector(userLocationSelector)

  const createSearchParams = useCreateSearchParams()

  /** This is the location currently used for the nearby products search */
  const [location, setLocation] = useState<Optional<CurrentLocation, 'timestamp'> | undefined>(
    getInitialLocation(searchLoc, userLoc),
  )

  /** Update the local search location when the device location or redux search location change */
  useCancelableFocusFx(
    async (isCurrent) => {
      const locationFromRedux = getInitialLocation(searchLoc, userLoc)
      if (locationFromRedux && !locationFromRedux.city) {
        // try to infer the city from the coords
        // here we want to know if the current search coordinates can be mapped to the name of some locality
        const parser = await getParsedAddressFromCoords(locationFromRedux.coordinate)

        // This parsed address does not need to be fully valid because for this purpose we only need the city, and optionally the state
        const addr = parser.result()
        if (addr.city) locationFromRedux.city = `${addr.city}${addr.state ? `, ${addr.state}` : ''}`
      }
      if (!isCurrent) return
      setLocation(locationFromRedux)
    },
    [searchLoc, userLoc],
  )

  const onSelectCity = useCallback(
    (item: Establishment) => {
      const loc = { city: item.name ?? '', coordinate: item.coordinate }
      // Update the component state with the new location. this should produce a search in the new location's coords
      setLocation(loc)
      // When selecting a city we should set it as the global search location so it can be used elsewhere
      dispatch(setSearchLocation(loc))
    },
    [dispatch],
  )

  const onExplorePress = useCallback(() => {
    // If an actual location is not selected, take user's location
    const city = searchLoc?.city || userLoc?.city
    const coordinate = searchLoc?.coordinate || userLoc?.coordinate

    if (coordinate)
      dispatch(
        setSearchLocation({
          city,
          coordinate,
        }),
      )
    navigation.navigate('ExploreScreen', createSearchParams({ coords: coordinate }))
  }, [createSearchParams, dispatch, navigation, searchLoc, userLoc])

  return (
    // This is intended to wait until the user location is set. In case it can't be obtained it is expected to be null. Undefined means it hasn't been set so it's loading
    <LoadingView loading={userLoc === undefined} style={styles.container}>
      <NearbyProdsInput initialValue={location?.city} onSelectCity={onSelectCity} onExplorePress={onExplorePress} />
      <View style={styles.spacing10} />
      <NearbyProductsResults coords={location?.coordinate} />
    </LoadingView>
  )
})

const styles = StyleSheet.create({
  container: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  spacing10: {
    height: 10,
  },
})
