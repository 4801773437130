import { ProcessedOrderSummary, ProcessedOrderSummaryProps } from '@components'
import { Button, Modal, Text, TextH1 } from '@elements'
import { getOrderNum, plural } from '@helpers/display'
import { Order } from '@models/Order'
import { isFarmBalance } from '@models/Product'
import { memo } from 'react'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { globalStyles } from '../../../../constants/Styles'
import { AdminOrderCancel } from '../ModalAdminOrderCancel'
import { isFarmCreditOnlyFunc } from '../helpers/orderCancelHelper'

import Colors from '@/constants/Colors'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { AccessRight, Permission } from '@helpers/Permission'
import { isDraftOrder } from '@models/DraftOrder'
import { getDraftOrderStatus } from '@helpers/orders-display'

type Props = ProcessedOrderSummaryProps & {
  onCloseCancelModal: () => void
  onPressMessageCustomer: () => void
}

export const OrderOverview = memo(function OrderOverview({
  currentOrder,
  firstInvoice,
  onCloseCancelModal,
  onPressMessageCustomer,
}: Props): JSX.Element {
  const styles = useStyles()
  const hasAccessCancel = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)

  /** cancel order function (digital products including farm balance product can not be cancelled) */
  const cancelOrder = (currentOrder: Order) => {
    const cancelledItems = currentOrder.items.filter((item) => !item.cancelled && !isFarmBalance(item.product))

    Modal(
      <AdminOrderCancel
        currentOrder={currentOrder}
        cancelledItems={cancelledItems}
        onClose={onCloseCancelModal}
        entireOrder
      />,
      {
        title:
          'Cancel Order - ' +
          getOrderNum(currentOrder.orderNum) +
          ' - ' +
          currentOrder.items.filter((item) => !item.cancelled).length +
          plural(currentOrder.items.filter((item) => !item.cancelled).length, ' Item'),
      },
    )
  }

  //decide if order is cancelled
  const isCancelledFunc = (currentOrder: Order) => {
    let allItemsCancelled = true
    currentOrder?.items?.forEach((item) => {
      if (!item.cancelled) {
        allItemsCancelled = false
      }
    })
    return allItemsCancelled
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <View style={globalStyles.flexRowCenter}>
          <TextH1 size={18}>Order Details</TextH1>
          {(isCancelledFunc(currentOrder) || isDraftOrder(currentOrder)) && (
            <Text size={12} style={styles.cancelledWarning} type="bold">
              <Text color={Colors.white}>
                {isDraftOrder(currentOrder) ? getDraftOrderStatus(currentOrder) : 'Order Cancelled'}
              </Text>
            </Text>
          )}
        </View>

        <View style={globalStyles.flexRowCenter}>
          <Button title="Message customer" onPress={onPressMessageCustomer} />
          {hasAccessCancel && (
            <View style={styles.buttonGroups}>
              <Button
                title="Cancel Order"
                onPress={() => cancelOrder(currentOrder)}
                disabled={isCancelledFunc(currentOrder) || isFarmCreditOnlyFunc(currentOrder)}
              />
            </View>
          )}
        </View>
      </View>
      <ProcessedOrderSummary isAdmin currentOrder={currentOrder} firstInvoice={firstInvoice} />
    </View>
  )
})

const useStyles = CreateResponsiveStyle(
  {
    container: {
      marginTop: -5,
    },
    cancelledWarning: {
      backgroundColor: Colors.red,
      color: Colors.white,
      paddingHorizontal: 10,
      paddingVertical: 5,
      borderRadius: 100,
      justifyContent: 'flex-start',
      marginLeft: 10,
    },
    headerContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    buttonGroups: {
      flexDirection: 'row',
    },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      container: {
        marginTop: -15,
      },
      headerContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  },
)
