import { makeDiscountedItemPayment, shouldAddFixedCoupon } from '@helpers/coupons'
import { deepClone, isNonNullish } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { sortByValue } from '@helpers/sorting'
import { pick } from '@helpers/typescript'
import { Coupon, CouponType, isFixedCoupon } from '@models/Coupon'
import { Invoice, InvoiceItem, InvoiceStatus, PaymentSources, invoiceItemTotal, invoiceTotal } from '@models/Invoice'
import { Infinite, Money, Zero } from '@models/Money'
import { SplitTenderPayment, SplitTenderPaymentPartial } from '@models/Order'
import { PaymentMethod, isEbtPayment, isInfinitePayment, mapSourceToType } from '@models/PaymentMethod'

import { getInvoicePaymentMethod } from './InvoiceService'

/** invoiceApplySplitTender applies the supplied payment breakdown to the invoice items.
 *
 * - Invoice items' payment source gets applied here by dependency @see {assignItemPayments}
 * - Warning: The invoice is modified in place.
 * */
export function invoiceApplySplitTender(
  invoice: Pick<Invoice, 'items' | 'amountTotal' | 'couponApplied'>,
  payments: SplitTenderPaymentPartial,
): void {
  // Copy payments to separate tender array, so we don't mess with the original payments
  const paymentsCopy = deepClone(payments)

  // Add the amounts that are finite first (e.g. Farm Credit or EBT)
  const paymentsSorted = paymentsCopy.sort((a, b) =>
    MoneyCalc.isLessThan(a.amount ?? Infinite, b.amount ?? Infinite) ? -1 : 1,
  )

  if (paymentsSorted.length < 1) {
    throw new Error('no default payment method specified')
  }

  // A copy of the coupon that can be mutated to keep track of remaining value, or will just hold a reference for the percent coupons
  const mutableCoupon = invoice.couponApplied?.coupon ? deepClone(invoice.couponApplied.coupon) : undefined

  // If the invoice does not have a coupon or the coupon is fixed then clear it, fixed coupons will be re-added
  if (invoice.couponApplied?.coupon) {
    invoice.items = invoice.items.map(resetInvoiceItemDiscount)
  }

  // We should limit a fixed coupon to never be more than the invoice total
  if (mutableCoupon && isFixedCoupon(mutableCoupon)) {
    mutableCoupon.value = MoneyCalc.min(mutableCoupon.value, invoiceTotal(invoice))
  }

  // Sort the items so that isEbtEligible items show up last, that way any discount we have we apply to non-eligible
  // items first then the rest is applied to the ebtEligible products. We do this because non-ebt eligible items are the
  // only items with constraints (can't be bought with ebt) so we should maximize coupon amounts here first
  invoice.items.sort(sortByValue((i) => i.isEbtEligible))

  // Now we update the payments in each of the items
  invoice.items = invoice.items.map((item) => {
    const itemCost = invoiceItemTotal(item)
    // If the item is cancelled or free we should not assign payments to it
    if (item.cancelled || MoneyCalc.isZero(itemCost)) return item

    // This determines the true item payment source
    const itemPaymentsBase = assignItemPayments(
      [],
      paymentsSorted,
      itemCost,
      pick(item, 'id', 'isEbtEligible', 'product'),
      mutableCoupon,
    )

    //We now need to divide the amount and discount by the quantity
    const itemPayments = itemPaymentsBase.map(({ amount, source, discount }) => {
      return {
        source,
        // We allow decimals here as it is used for precise splitting
        amount: MoneyCalc.divide(amount, item.quantity),
        discount: discount ? MoneyCalc.divide(discount, item.quantity) : undefined,
      }
    })

    return {
      ...item,
      payments: itemPayments,
    }
  })

  // Recompute the total due after factoring in coupons and payments
  invoice.amountTotal = invoiceTotal(invoice)
}

// Will assign coupons to the invoice if they exist
const assignItemCoupons = (
  itemPayments: InvoiceItem['payments'],
  payments: SplitTenderPaymentPartial,
  itemCost: Money,
  invoiceItem: Pick<InvoiceItem, 'id' | 'isEbtEligible' | 'product'>,
  coupon: Coupon<CouponType.Fixed>,
): InvoiceItem['payments'] => {
  // Check if the fixed coupon should not be added to the invoice item
  if (!shouldAddFixedCoupon(coupon, invoiceItem)) {
    // If the fixed coupon should not be added, proceed with assigning payments to the item
    // This is done without applying the fixed coupon
    return assignItemPayments(itemPayments, payments, itemCost, invoiceItem)
  }

  // If the coupon has enough to cover the entire item cost then use it
  if (MoneyCalc.isGTE(coupon.value, itemCost)) {
    itemPayments.push({ source: PaymentSources.OFFLINE, amount: Zero, discount: itemCost })
    // update the new amount remaining on the payment
    coupon.value = MoneyCalc.subtract(coupon.value ?? Infinite, itemCost)
    return itemPayments
  }

  // If we only have enough for a partial payment then
  if (MoneyCalc.isGTZero(coupon.value)) {
    // Here we set the amount to 0 and the discount because it is just one payment, it is not setting the item cost to 0
    itemPayments.push({ source: PaymentSources.OFFLINE, amount: Zero, discount: coupon.value })
    // Move on to the payments now that the coupon has been fully applied, and update the coupon remaining amount
    const newItemCost = MoneyCalc.subtract(itemCost, coupon.value)
    coupon.value = Zero

    return assignItemPayments(itemPayments, payments, newItemCost, invoiceItem)
  }

  // If we try and use more money than is on this coupon throw an error
  throw new Error('Attempted to use more than is available on this coupon.')
}

/** Will recursively assign payments to the invoice items
 *
 * - This determines the true payment source of an invoice item.
 */
const assignItemPayments = (
  itemPayments: InvoiceItem['payments'],
  payments: SplitTenderPaymentPartial,
  itemCost: Money,
  invoiceItem: Pick<InvoiceItem, 'id' | 'isEbtEligible' | 'product'>,
  coupon?: Coupon,
): InvoiceItem['payments'] => {
  // If we have a coupon, and it has more than $0 then apply that coupon first
  if (coupon && isFixedCoupon(coupon) && MoneyCalc.isGTZero(coupon.value)) {
    return assignItemCoupons(itemPayments, payments, itemCost, invoiceItem, coupon)
  }

  // If we have no payments left and still need to pay more than 0 then throw an error
  if (payments.length === 0) {
    throw new Error('This order requires an additional payment method.')
  }

  // If the item is not ebt eligible and the payment is ebt then move on to the next payment to attempt
  if (!invoiceItem.isEbtEligible && isEbtPayment({ type: mapSourceToType(payments[0].paymentMethod.source) })) {
    return assignItemPayments(itemPayments, payments.slice(1), itemCost, invoiceItem, coupon)
  }

  // If the first payment has enough to cover the entire item cost then use it
  const itmPmt = makeDiscountedItemPayment(itemCost, payments[0].paymentMethod.source, {
    itemId: invoiceItem.id,
    coupon,
    product: invoiceItem.product,
  })
  if (MoneyCalc.isGTE(payments[0].amount ?? Infinite, itmPmt.amount)) {
    itemPayments.push(itmPmt)
    // update the new amount remaining on the payment
    payments[0].amount = MoneyCalc.subtract(payments[0].amount ?? Infinite, itmPmt.amount)
    return itemPayments
  }

  // If we only have enough for a partial payment then
  if (MoneyCalc.isGTZero(payments[0].amount ?? Infinite)) {
    const partialItmPmt = makeDiscountedItemPayment(payments[0].amount!, payments[0].paymentMethod.source, {
      itemId: invoiceItem.id,
      coupon,
      // We use this so that we return the discount amount as separate from the payment[0].amount because the amount is
      // how much we will pay, and we don't want to include the discount in that
      excludingDiscount: true,
      product: invoiceItem.product,
    })
    itemPayments.push(partialItmPmt)
    // Remove the first payment method now that it has been fully applied
    const newItemCost = MoneyCalc.subtract(
      itemCost,
      MoneyCalc.add(partialItmPmt.amount, partialItmPmt.discount || Zero),
    )
    payments.shift()
    return assignItemPayments(itemPayments, payments, newItemCost, invoiceItem, coupon)
  }

  // The current payment has $0 left on it
  if (payments.length === 1) {
    throw new Error('This order requires an additional payment method.')
  } else {
    // If we still have more payments remove the first and move on to the next one
    payments.shift()
    return assignItemPayments(itemPayments, payments, itemCost, invoiceItem)
  }
}

/**
 * Will add a new tender to an invoice with the highest priority if finite and recompute the split tender. Any newly
 * unused payments will be left on the invoice untouched, but items will no longer reference their sources.
 * NOTE: If there is a payment with the same source it will be overwritten by the new tender.
 * @param invoice the existing invoice to apply the new payments to
 * @param tender a single tender that will be added to the invoice
 */
export function invoiceAddNewTender(invoice: Invoice, tender: SplitTenderPayment[0]): void {
  const tenderSource = tender.paymentMethod.source

  // If the invoice is not due or failed then we cannot alter the payments
  if (invoice.status !== InvoiceStatus.Due && invoice.status !== InvoiceStatus.Failed) {
    throw new Error(`You cannot add a payment to a ${invoice.status} invoice.`)
  }

  // Parse sources from the invoice and make a tender array
  const newTenderArray = [tender, ...parseInvoicePayments(invoice)]

  // Save the new tender to the payments
  invoice.payments[tenderSource] = {
    source: tenderSource,
    paymentMethod: getInvoicePaymentMethod(tender.paymentMethod),
    totalPaid: Zero,
  }

  // Run apply split tender on that list
  invoiceApplySplitTender(invoice, newTenderArray)
}
// Will convert an invoices payments to a split tender array

export function parseInvoicePayments(inv: Invoice): SplitTenderPaymentPartial {
  const sourceAmts: Map<PaymentSources, Money> = new Map()

  // Loop through each item payment to sum the totals up
  inv.items.forEach((itm) => {
    itm.payments.forEach((itmPayment) => {
      // Add the current payment amount to the respective source total
      const existingAmt = sourceAmts.get(itmPayment.source)
      if (existingAmt !== undefined) {
        const newTotal = MoneyCalc.add(existingAmt, itmPayment.amount)
        sourceAmts.set(itmPayment.source, newTotal)
      } else {
        sourceAmts.set(itmPayment.source, itmPayment.amount)
      }
    })
  })

  // Create the split tender object
  return (
    Object.keys(inv.payments)
      .map((key) => {
        const source = key as PaymentSources
        const sourceTotal = sourceAmts.get(source) ?? Zero

        // If the amount is Zero then we should not add it as a payment as it is not being used
        if (MoneyCalc.isZero(sourceTotal)) return undefined

        // If the source is infinite we can ignore the amount above as it will automatically cover the difference
        if (isInfinitePayment(source)) {
          return { paymentMethod: { source } as Pick<PaymentMethod, 'source'> }
        }
        // If it is finite then add the amount from the source total as the amount for this tender
        return { paymentMethod: { source } as Pick<PaymentMethod, 'source'>, amount: sourceTotal }
      })
      .filter(isNonNullish)
      // Will sort them least to greatest in terms of amount
      .sort((a, b) => (MoneyCalc.isLessThan(a.amount ?? Infinite, b.amount ?? Infinite) ? -1 : 1))
  )
}

// Will remove any discount from the item passed in
function resetInvoiceItemDiscount(itm: InvoiceItem): InvoiceItem {
  // add the discount amount back into the payment amount to remove the discount
  const payments = itm.payments.map((pmt) => ({
    ...pmt,
    discount: undefined,
    amount: MoneyCalc.add(pmt.amount, pmt.discount || Zero),
  }))

  return {
    ...itm,
    payments,
  }
}
