import { backOff } from '@helpers/rateLimiter'
import uuid from '@helpers/uuid'
import { httpsCallable } from 'firebase/functions'

import { hasOwnProperty, removeUndefined } from '@helpers/helpers'
import { ErrorWithCode, firebaseClientInternalErr } from '@shared/Errors'
import { EndpointMap, EndpointNames } from '@shared/types/v2/endpoint'
import { isEmul } from '../../config/Environment'
import { Logger } from '../../config/logger'
import { functions } from '../db'
import { isFirebaseClientInternalErrorRaw, isFirebaseError, isServerErrorWithCode } from './errors'

/** Performs a Firebase call to the function of the given name with the supplied params as arguments. Calls
 * to call are retried with exponential back-off when a failed request is encountered. The requests will pass an
 * idempotency key to the server. By default, the idempotency key is a generated uuid, but the caller may specify
 * their own.
 * @deprecated use callEndpoint instead */
export async function call<TRequest, TResponse = any>(
  name: string,
  params: TRequest,
  idempotencyKey = uuid(),
): Promise<TResponse> {
  const fn = httpsCallable<TRequest, TResponse>(functions(), name)
  return await backOff(
    async () => {
      const { data } = await fn({ ...params, _idempotencyKey: idempotencyKey })
      return data
    },
    { retry: shouldRetry },
  )
}

/**
 * Call endpoint will call the server function at a specific endpoint to handle the request.
 * @param endpoint The endpoint that should handle the request
 * @param params The params to pass to the request
 * @param idempotencyKey An optional idempotency key for the function call
 */
export async function callEndpoint<T extends EndpointNames>(
  endpoint: T,
  params: EndpointMap[T]['request'],
  idempotencyKey = uuid(),
): Promise<EndpointMap[T]['response']> {
  const fn = httpsCallable<EndpointMap[T]['request'], EndpointMap[T]['response']>(functions(), 'v2-apiEndpoint')
  try {
    /** It's important to remove any undefined values from the data, because otherwise they may get converted into null values when they reach the server side, which may produce unintended behaviors */
    const cleanedData = removeUndefined(params ?? {})

    return await backOff(
      async () => {
        const { data } = await fn({ ...cleanedData, endpoint, _idempotencyKey: idempotencyKey })
        return data
      },
      { retry: shouldRetry },
    )
  } catch (err) {
    // A client internal error will be thrown when the error does not come from the server, meaning it is likely a client-side network problem
    if (isFirebaseClientInternalErrorRaw(err)) {
      firebaseClientInternalErr(params)
    }
    // If the error is of type ServerErrorWithCode then we can translate it back to the original error
    if (isServerErrorWithCode(err)) {
      // Note: The "data" is being omitted intentionally
      throw new ErrorWithCode({ code: err.details.code, devMsg: err.details.devMsg, uiMsg: err.details.uiMsg })
    }

    // Otherwise we throw the error as is
    throw err
  }
}

/** retryableCodes holds a set of FirebaseError codes which may be transient and should be retried. All others are considered to be non-retryable errors.
 * - codes prefixed by "functions/*" are considered errors originated from the server.
 */
const retryableCodes = new Set([
  'functions/cancelled',
  'functions/deadline-exceeded',
  'functions/aborted',
  'functions/out-of-range',
  'functions/unavailable',
  // This is an error thrown from the Firebase Client library, and is likely a network error, so it should be retryable
  'internal',
])

/** Determines if a retry should be done when an endpoint call fails */
function shouldRetry(err: unknown): boolean {
  // Make sure this is a firebase error with a code
  if (!isFirebaseError(err) || !hasOwnProperty(err, 'code') || typeof err.code !== 'string') {
    return false
  }
  if (isEmul) {
    // If this would normally be retried then inform the developer it should be changed to non-retryable
    if (retryableCodes.has(err.code)) {
      Logger.warn('Skipping retry in emulator')
    }
    return false
  }

  return retryableCodes.has(err.code)
}
