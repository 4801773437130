import { PartialExcept, Undefine } from '@helpers/typescript'

import { Commodity } from './Commodity'
import { Distribution, DistributionConstraint } from './Distribution'
import { Farm } from './Farm'
import { Money } from './Money'
import { PaymentType } from './Payment'
import { DateRange } from './Schedule'

import { hasOwnProperty, isObject } from '@helpers/helpers'
import { AlgoliaGeoDoc, AlgoliaGeoProduct } from './Algolia'
import { ProductFee } from './ProductFee'
import { ReportType, SummaryItem } from './Summary'

/** For all these cancellation types, if the change window has passed then the only option is contacting the farmer */
export enum CancellationTypes {
  // Customers can cancel anytime before the CSA change window (full window)
  Flexible = 'flexible',

  // Customers can cancel anytime one month before the CSA season begins (full refund)
  FlexPreSeason = 'flexible-pre',

  // Customers can cancel and receive credit towards the following year's share or pre-orders (farm credit)
  Moderate = 'moderate',

  // Shares can be cancelled, but they are not refundable
  Strict = 'strict',
}

/** ProductType identifies the type of product available for purchase. */
export enum ProductType {
  // A Standard products are available for sale as individual units.
  Standard = 'standard',

  // A PrimaryShare is sold as a box of products.
  PrimaryShare = 'primary',

  // An AddonShare is a box of products sold alongside a PrimaryShare.
  AddonShare = 'addon',

  /** A generic digital product with similar behavior as a `GlobalStandard`. Encompasses Donations and other digital products without custom functionality */
  Digital = 'digital',

  /** FarmBalance is for farm farm credit */
  FarmBalance = 'farm-balance',
}

/** BaseProduct defines the fields shared by all products */
export type BaseProduct = {
  // The document ID.
  id: string

  // Populated farm data for the product (Currently only these properties are used)
  farm: Pick<Farm, 'id' | 'timezone' | 'address' | 'name' | 'urlSafeSlug'>

  // The type of product.
  type: ProductType

  // The name of the product.
  name: string

  // A brief description of the product.
  description: string

  // A more detailed description of the product.
  longDescription: string

  /** A product the product belongs to. There are global categories that are available as default options to all farms, and there's also farm-specific categories they may add */
  category: string

  // Images for the product
  images: string[]

  // The number of units that have been sold. A product is considered to be sold when it has a pickup scheduled for
  // its distribution to the customer. A product ordered to be picked up twice will be counted twice.
  purchasedQuantity?: number

  // Hashtags are assigned the product to help find and categorize the product being offered.
  hashtags?: string[]

  // Draft products means they are invalid and require some change before they can be sold. Saving them clears this state
  isDraft?: boolean

  // Setting this to true hides the product in the farm shop, farmers can still buy this product for customers
  isHidden: boolean

  // Featured products are listed at the top in the store as well as on the farm home page
  isFeatured?: boolean

  // CSA is required for Shares and optional for other types
  csa?: string[]

  /** isPrivate is meant to encode all logic about a product's privacy status into a boolean that has no external dependencies.
   * - If the product belongs to only private CSAs isPrivate will be true; Except if it's a Standard or Digital product it also needs to have the "hideFromShop" setting to true, to be considered private in this sense.
   * - This field is meant to be automatically updated by a csa trigger */
  isPrivate: boolean

  /** urlSafeSlug is a unique string name for a product across all products under a same farm and is intended to be used in URLs to represent a product which owns this urlSafeSlug under a farm.  */
  urlSafeSlug: string

  /** Taxes or Fees applied to the product */
  taxesAndFees: {
    /** Whether the product is tax-free */
    isTaxExempt: boolean

    /** All productFees applied to the product
     *
     * - Note: The server type for this is `Record<ProductFee['id'], ProductFee>`
     */
    fees?: ProductFee[]
  }
}

/** Traits shared by @see {PhysicalProduct}. This handles everything to do with getting the product to the customer, does not apply to digital products */
export type PhysicalBase = {
  /** Locations and schedules for when a product is distributed
   *
   * FIXME: Real product distribution type, need to work on migrating the code over to this type
   * `distributions: Omit<Distribution, 'farm'>[]`
   *
   * - Note: The server type for this is `Record<Distribution['id'], Omit<Distribution, 'farm'>>`
   */
  distributions: Distribution[]

  /** Additional constraints to further modify the schedule, if undefined resort to schedule values */
  distributionConstraints?: DistributionConstraint[]

  /** List of certifications for the product */
  certification?: object

  /** A description of how this (physical) product is grown. The value is created as a freestyle string the user can input */
  productionMethod?: string

  /** The producer of the physical product. The value comes from the Farm model's 'localProducers' field */
  producer?: string
}

/** Indicates whether a product tracks its buying options quantities from either a global stock or per-unit stock */
export enum StockType {
  Global = 'global',
  Unit = 'unit',
}

/** Traits shared by products that have buying options (Non-shares) @see {UnitProduct} */
export type UnitProductCommon = {
  /** A product's base unit is the main unit of measurement for this product's buying options. Its value may be selected from a set of global base units, or may be a custom value set by the farmer */
  baseUnit: BaseUnit | string

  /** Whether quantity is specified on the unit level, and NOT at the global level. */
  unitStock: boolean

  /**
   * Global Stock
   * The number of base units available for purchase across all buying options. This value is only used for global stock products.
   * If the 'unitStock' field is true, this is expected to be undefined. If false, this is expected to be a number with the global quantity */
  quantity?: number

  /** This string will be prefixed to each unit's sku in standard products, when editing through the UI. */
  unitSkuPrefix?: string

  /** pricePerUnit is applicable to unit-stock products only. In those products, this is used to automatically generate the value in `Unit.price` */
  pricePerUnit?: Money

  /** This is a boolean field controlled manually by the user. Its meaning, purpose and effect is a bit convoluted and unintuitive because it has different behaviors in different screens:
   *
   * The original idea when it was created:
   * - "Whether the product should be shown only in the csa screen and not in the shop."
   *
   * The current behavior requirements, through evolutionary change:
   * - In FarmDetails screen it should filter out any products where this is true, even if they're featured.
   * - In the homescreen and shop, products for which this is true AND they belong ONLY to private CSAs, should not be shown.
   * - Also in the homescreen and shop, if this is set to true, but the product isn't assigned to only private CSAs, the product should be shown but can't be added to cart directly; Instead the card gets the "View CSA" button, so as to force the user to enter the CSA screen before adding to the cart.
   *  */
  hideFromShop?: boolean
}

/** Product fields whose specific values depend on a generic stock type */
export type StockDependent<T extends StockType | undefined = undefined> = T extends StockType.Unit
  ? {
      quantity?: undefined
      units: UnitBase<StockType.Unit>[]
      pricePerUnit: Money
      unitStock: true
    }
  : T extends StockType.Global
  ? {
      quantity: number
      units: UnitBase<StockType.Global>[]
      pricePerUnit?: undefined
      unitStock: false
    }
  : {
      units: UnitBase[]
    }

/** Represents the eligibility status for EBT for a standard product. */
export enum EbtEligibility {
  /** Indicates that the product is not eligible for purchase with an EBT payment method. This is the default. */
  NotEbtEligible = 'notEbtEligible',

  /** Indicates that the product is eligible for purchase with both an EBT and non-EBT payment method */
  EbtEligible = 'ebtEligible',

  /** Indicates that the product can only be purchased with an EBT payment method.
   * - This means that any orders with this product must have EBT as a payment method (does not need to be fully paid with EBT; Partial EBT is fine too) */
  EbtOnly = 'ebtOnly',
}

/** Fields present only in standard products, and shared for all standard subtypes  */
export type StandardCommon = {
  /** The kind of farm good being sold. */
  commodity?: Pick<Commodity, 'id' | 'name'>

  /** The variety of commodity being sold. */
  variety?: string

  /** The cost of production per base unit. */
  costOfProduction?: Money

  /** Whether the product can be purchased across availability (For some of its future dates). If disabled, only the next date can be purchased. I.e. no choosing of pickup date/s because only the next one is allowed.
   */
  disableBuyInFuture?: boolean

  /** The number of pickups required when purchasing the product. */
  minPickups?: number

  /** The EBT eligibility status for the product. See documentation for EbtEligibility above */
  ebtEligibility: EbtEligibility

  /** This value is set by a selector in the product details form. It imposes catalog-specific validation standards on the product fields. It is meant to make the product values better suited to a given catalog association.
   * - If undefined, the product is retail
   */
  defaultCatalog?: DefaultCatalog
}

/** **Standard** is a product kind that sells an individual commodity. Can be of either global stock or unit stock type. This type isn't supposed to be used directly in the app, but rather as a building block for other types */
export type StandardBase<T extends StockType | undefined = undefined> = BaseProduct &
  PhysicalBase &
  UnitProductCommon &
  StandardCommon &
  StockDependent<T> & {
    type: ProductType.Standard
  }

/** A digital product has no physical distributions. It inherits the properties of a global standard product. This type isn't supposed to be used directly in the app, but rather as a building block for other types */
export type DigitalBase = BaseProduct &
  Undefine<PhysicalBase> &
  Undefine<UnitProductCommon, 'pricePerUnit'> &
  StockDependent<StockType.Global>

export type DigitalStandard = DigitalBase & { type: ProductType.Digital }

export type FarmBalance = DigitalBase & { type: ProductType.FarmBalance }

/** The ShareCommon type has the properties shared among Share product sub types */
export type ShareCommon = {
  /** The global number of units available for purchase */
  quantity: number

  /** Details about the template parent product if this is a template child */
  templateProduct?: Pick<BaseProduct, 'id' | 'name'>

  isChild?: boolean

  templateProductId?: string

  /** The SKU for the overall share */
  sku?: string

  csa: string[]

  // Optional information available for shares to further describe what is contained within the share.
  shareInfo: {
    minimumNumberOfItems?: number
    maximumNumberOfItems?: number
    suggestedFamilySize?: number
  }

  /** The number of pickups included for the price of the share. Expected to be greater than 0 */
  numberPickups: number

  /** Different payment options available for the share */
  paymentSchedules: PaymentSchedule[]

  /**
   * If shares are unavailable customers can add themselves to a waitlist. Waitlisted customers will be notified when
   * more inventory is available and given 24 hours to purchase their share before additional customers are notified.
   * Waitlisted customers are stored in the Waitlist model. */
  waitlist?: boolean

  /** vacationWeeks specifies how many vacation weeks where a customer may cancel their share pickups and receive credit for that time. This policy supersedes the blanket cancellation policy a farm manager selects for a share for the given number of weeks only */
  vacationWeeks: number

  /**
   * cancellationPolicy defines the terms for cancellation of the share.
   * flexible: Customers can cancel anytime before the CSA change window
   * flexible-preseason: Customers can cancel anytime one month before the CSA season begins
   * moderate: Customers can cancel and receive credit towards the following year's share or pre-orders
   * strict: Shares can be cancelled, but they are not refundable */
  cancellationPolicy?: CancellationTypes
}

/** A Share is a type of product that provides the sale of CSA subscription shares. This type isn't supposed to be used directly in the app, but rather as a building block for other types */
export type ShareBase<
  T extends ProductType.PrimaryShare | ProductType.AddonShare = ProductType.PrimaryShare | ProductType.AddonShare,
> = BaseProduct & PhysicalBase & ShareCommon & { type: T }

//** ********************************** MAIN PRODUCT ****************************************** */

export type UnitStandard = StandardBase<StockType.Unit>

export type GlobalStandard = StandardBase<StockType.Global>

export type Standard = UnitStandard | GlobalStandard

export type PrimaryShare = ShareBase<ProductType.PrimaryShare>

export type AddonShare = ShareBase<ProductType.AddonShare>

export type Share = PrimaryShare | AddonShare

/**
 * A Product represents an item in the catalog.
 */
export type Product = UnitStandard | GlobalStandard | AddonShare | PrimaryShare | DigitalStandard | FarmBalance

/** The type identifier of a template product. This exists separately from the main product type because template products are not full products in themselves. */
export const TemplateType = 'template'

/**
 * A TemplateProduct is not actually a full product, it is just a reusable template with already filled in data and can be applied to an actual product, so that is why TemplateProduct has its own collection and not inside the products collection.
 */
export type TemplateProduct = Pick<
  Share,
  | 'id'
  | 'name'
  | 'description'
  | 'longDescription'
  | 'category'
  | 'images'
  | 'producer'
  | 'shareInfo'
  | 'sku'
  | 'productionMethod'
> & {
  type: typeof TemplateType
  isDeleted?: boolean
  isHidden?: boolean
  farm: Pick<Share['farm'], 'address' | 'id' | 'name' | 'timezone'>
}

/** Physical products tangible material goods. Excludes digital product types from Product union*/
export type PhysicalProduct = UnitStandard | GlobalStandard | AddonShare | PrimaryShare

/** A digital product is a virtual non-material product or service. Includes various subtypes, all of which are non physical */
export type DigitalProduct = DigitalStandard | FarmBalance

/** Product types that have units/ buying-options */
export type UnitProduct = Standard | DigitalProduct

/** Product types whose stock is tracked as a single global quantity */
export type GlobalStockProduct = Share | GlobalStandard | DigitalProduct

export type PaymentSchedule = {
  /** How payments should be processed. */
  paymentType: PaymentType

  /** How often billing should take place. */
  frequency: 'ONCE' | 'WEEKLY' | 'MONTHLY' | 'PER-PICKUP'

  /**
  The payment end dates define the payment window used for seasonal billing.
  The start date is based on the pickupStart date and does not need to be included here
  The endDate determines the last day installments can be made, will take the earlier value between last pickup and payment endDate */
  paymentDates: PartialExcept<DateRange, 'endDate'>

  /** The total amount that will be billed for the share. */
  amount: Money

  /** The amount to be paid upfront when the payment schedule option is selected. */
  deposit: Money
}

export type PayPerPickup = PaymentSchedule & {
  paymentType: PaymentType.INSTALLMENTS
  frequency: 'PER-PICKUP'
  deposit: { value: 0; currency: Money['currency'] }
}

export type PayInFull = PaymentSchedule & {
  paymentType: PaymentType.PAY_FULL
  frequency: 'ONCE'
}

export const isPayInFull = (ps: Pick<PaymentSchedule, 'paymentType' | 'frequency'>): ps is PayInFull =>
  ps.paymentType === PaymentType.PAY_FULL && ps.frequency === 'ONCE'

export const isPayPerPickup = (ps: Pick<PaymentSchedule, 'paymentType' | 'frequency'>): ps is PayPerPickup =>
  ps.paymentType === PaymentType.INSTALLMENTS && ps.frequency === 'PER-PICKUP'

/** Represents a buying option for a unit product */
export type Unit = UnitBase<StockType.Global> | UnitBase<StockType.Unit>

/** Type helper to form a buying option based on a generic stock type */
export type UnitBase<T extends StockType | undefined = undefined> = {
  id: string

  /** A name describing the unit */
  name: string

  /** The StockType Keeping Unit for this buying option. When editing through the UI, the final string for this field should
   * be saved already including the prefix from the product's `unitSkuPrefix` */
  sku: string

  /** The number of base-units this aggregate buying option represents. In global stock products, a unit's multiplier refers to an amount of base-units from the global product.quantity, for each item.quantity in cart. In unit stock products, the unit multiplier refers to the base-units of the same unit.quantity */
  multiplier: number

  /** A set of prices the buying option may have. This allows a product to have pricing options specific to a buyer, institution or app mode */
  prices: UnitPrice[]

  /**
   * UnitStock quantity
   *
   * - In UnitStock products, it's the number of units available for this buying option; It is expressed not as raw base-units (as in the global product.quantity), but as the available quantity of this aggregate buying option.
   * - In GlobalStock, it's undefined because the global quantity is tracked via the quantity field of the main product.
   */
  quantity?: number
} & UnitQuantity<T>

/** Dynamic type that determines the expected quantity type based on the stock type */
type UnitQuantity<T extends StockType | undefined = undefined> = T extends StockType.Unit
  ? { quantity: number }
  : T extends StockType.Global
  ? { quantity?: undefined }
  : unknown

/** A price for a buying option */
export type UnitPrice = {
  id: string
  name: string

  /** The price of this buying option */
  amount: Money

  /** Behaves like inventory, can only sell a max of this amount */
  maxCount?: number

  /** How many have been purchased */
  purchasedCount?: number

  /** This suffix will be appended to this combination of unit & price */
  skuSuffix?: string

  /** Associates this price to a given price group. Allows us to provide a price specific to certain types of customers or app modes.
   * - By default buying options are considered part of the default retail catalog
   */
  priceGroup?: PriceGroup
}

/** Price catalogs available by default.
 * - Retail farmers can only assign their products and schedules to the retail catalog.
 * - Wholesale farmers may assign them to wholesale, retail, or both.
 */
export enum DefaultCatalog {
  Wholesale = 'wholesale',
  Retail = 'retail',
  WholesaleRetail = 'wholesale-retail',
}

export type DefaultCatalogPriceGroup = {
  /** A price group defined by one of the default grownby catalogs */
  type: 'default-catalog'

  /**
   * - If the value is wholesale, the buying option will have this price in the wholesale app
   * - If the value is retail, the buying option will have this price in the retail app
   *
   * Note: Each price can be only either retail or wholesale, not both. Wholesale-retail is only used on the product level. If the buying option is for a wholesale-retail product, then the buying option may have one retail and one wholesale price.
   */
  catalog: DefaultCatalog.Retail | DefaultCatalog.Wholesale
}

export type CustomCatalogPriceGroup = {
  /** A price group defined by a custom, user-generated catalog. (Needs future specification) */
  type: 'custom-catalog'
  catalogId: string
}

/** Associates a price to a reference group. Each possible association may carry its own unique functionalities */
export type PriceGroup = DefaultCatalogPriceGroup | CustomCatalogPriceGroup

/** BaseUnit describes the characteristics of a buying option. These are globally available base units used as defaults when editing a product */
export enum BaseUnit {
  Bouquet = 'Bouquet',
  Bunch = 'Bunch',
  Stem = 'Stem',
  Each = 'Each',
  Ounce = 'Ounce',
  Pound = 'Pound',
  Pint = 'Pint',
  Quart = 'Quart',
  HalfGallon = 'Half Gallon',
  Gallon = 'Gallon',
  Bushel = 'Bushel',
  Share = 'Share',
}

/** baseUnit returns the base unit of a product. An error will be thrown if the base unit cannot be determined. */
export function getBaseUnit(
  product: Pick<Product, 'type'> | Pick<Standard, 'type' | 'baseUnit'> | SummaryItem<ReportType>['product'],
): BaseUnit | string {
  if ('baseUnit' in product && product.baseUnit) {
    return product.baseUnit
  }
  if (isShare(product)) {
    return BaseUnit.Share
  }
  throw new Error('product baseUnit not specified')
}

//////////////////////////////////////////////
// Type narrowing helpers:

export function isShare(prod: AlgoliaGeoDoc<AlgoliaGeoProduct>): prod is AlgoliaGeoDoc<AlgoliaGeoProduct>
export function isShare(type: ProductType): type is ProductType.AddonShare | ProductType.PrimaryShare
export function isShare(product: Pick<Product, 'type'>): product is Share
/** isShare returns true if the supplied product is a share type (PrimaryShare, AddonShare) otherwise returning false. */
export function isShare(
  product: Pick<Product, 'type'> | ProductType,
): product is Share | ProductType | AlgoliaGeoDoc<AlgoliaGeoProduct> {
  const type = typeof product === 'object' ? product.type : product
  return type === ProductType.PrimaryShare || type === ProductType.AddonShare
}

/** Identifies an addon share */
export function isAddon(product: Pick<Product, 'type'>): product is AddonShare {
  return product.type === ProductType.AddonShare
}

/** Identifies a primary share */
export function isPrimary(product: Pick<Product, 'type'>): product is PrimaryShare {
  return product.type === ProductType.PrimaryShare
}

export function isStandard(prod: AlgoliaGeoDoc<AlgoliaGeoProduct>): prod is AlgoliaGeoDoc<AlgoliaGeoProduct>
export function isStandard(type: ProductType): type is ProductType.Standard
export function isStandard(prod: Pick<Product, 'type'>): prod is Standard
/** Identifies a standard product */
export function isStandard(
  value: Pick<Product, 'type'> | Product['type'] | AlgoliaGeoDoc<AlgoliaGeoProduct>,
): value is Standard | ProductType.Standard | AlgoliaGeoDoc<AlgoliaGeoProduct> {
  return (typeof value === 'object' ? value.type : value) === ProductType.Standard
}

/** Identifies a buying option belonging to a global stock product */
export function isGlobalUnit(u: Unit): u is UnitBase<StockType.Global> {
  return u.quantity === undefined
}

/** Identifies a buying option belonging to a unit stock product */
export function isStockUnit(u: Unit): u is UnitBase<StockType.Unit> {
  return typeof u.quantity === 'number'
}

/** Identifies a unitPrice of a buying option belong to DefaultCatalogPriceGroup  */
export function isDefaultCatalogPriceGroup(p: PriceGroup): p is DefaultCatalogPriceGroup {
  return p.type === 'default-catalog'
}

export function hasUnits(prod: AlgoliaGeoDoc<AlgoliaGeoProduct>): prod is AlgoliaGeoDoc<AlgoliaGeoProduct>
export function hasUnits(prod: Pick<Product, 'type'>): prod is UnitProduct
/** Identifies products which have units. That includes all subtypes of standard and digital products. Useful when working with units across various product subtypes */
export function hasUnits(
  prod: Pick<Product, 'type'> | AlgoliaGeoDoc<AlgoliaGeoProduct>,
): prod is UnitProduct | AlgoliaGeoDoc<AlgoliaGeoProduct> {
  return isDigital(prod) || isStandard(prod)
}

/** Identifies a product whose stock is managed separately for each buying option (opposite of global stock) */
export function hasUnitStock(prod: Pick<Product, 'type'> | Pick<Standard, 'type' | 'unitStock'>): prod is UnitStandard {
  return hasUnits(prod) && prod.unitStock === true
}

/** Identifies a product whose stock is managed globally for all its buying options */
export function hasGlobalStock(
  prod: Pick<Product, 'type'> | Pick<Standard | DigitalProduct, 'type' | 'unitStock'>,
): prod is GlobalStockProduct {
  return (hasUnits(prod) && !prod.unitStock) || !hasUnits(prod)
}

/** Identifies a product both standard and global stock */
export function isGlobalStandard(
  prod: Pick<Product, 'type'> | Pick<Standard, 'type' | 'unitStock'>,
): prod is GlobalStandard {
  return isStandard(prod) && !prod.unitStock
}

export function isPhysical(p: AlgoliaGeoDoc<AlgoliaGeoProduct>): p is AlgoliaGeoDoc<AlgoliaGeoProduct>
export function isPhysical(p: Pick<Product, 'type'>): p is PhysicalProduct
/** Indicates a product is physical, meaning it is tangible material goods */
export function isPhysical(
  p: Pick<Product, 'type'> | AlgoliaGeoDoc<AlgoliaGeoProduct>,
): p is PhysicalProduct | AlgoliaGeoDoc<AlgoliaGeoProduct> {
  return p.type === ProductType.Standard || p.type === ProductType.PrimaryShare || p.type === ProductType.AddonShare
}

export function isDigital(type: ProductType): type is ProductType.Digital | ProductType.FarmBalance
export function isDigital(p: Pick<Product, 'type'>): p is DigitalProduct
/** Identifies a digital product */
export function isDigital(p: Pick<Product, 'type'> | ProductType): p is DigitalProduct | ProductType {
  return typeof p === 'object' ? !isPhysical(p) : !isPhysical({ type: p })
}

/** identifies an unknown object as a generic product */
export function isProduct(obj: any): obj is Product {
  return (
    isObject(obj) &&
    hasOwnProperty(obj, 'id') &&
    hasOwnProperty(obj, 'type') &&
    Object.values(ProductType).includes(obj.type as ProductType)
  )
}

/** Identifies a farm balance product */
export function isFarmBalance(p: Pick<Product, 'type'>): p is FarmBalance {
  return p.type === ProductType.FarmBalance
}
